import {React , useEffect} from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "./App.css";


const AboutOrangeLoft = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div>
        <Navbar />


        <div className="container-fluid">
        
        <div className="row mt-4">
        <div className="col-sm-4"> 
        <img src="/images/murree1.jpeg" width="107.6%" height="400px" />
        </div>
        <div className="col-sm-8 col-padding" style={{padding:"100px"}}>
        <h2>About Orangeloft</h2>
       <p className="mt-3">Orange Loft is a pioneering hospitality brand up-North that guarantees tourists (from across the Nation and Worldwide), an unforgettable stay in all the Cultural/Scenic landmarks of Pakistan. With a Mission to Reinvent Hospitality – from Cityscape to Landscape – Orange Loft offers standardized and hassle-free lodgings for its guests that allow them to truly relish a stay at their favorite spot!</p>
        </div>
        </div>

        <div className="row">
        <div className="col-sm-4">
        <img src="./images/mushkpuri3.jpeg" alt="image" height="400px"/>
        </div>
        <div className="col-sm-4">
        <img src="./images/shogran1.jpeg" alt="image" height="400px"/>
        </div>
        <div className="col-sm-4">
        <img src="./images/naran1.jpeg" alt="image" height="400px"/>
        </div>
        
        </div>
        <div className="row">
        <div className="col-sm-5">

        </div>
        <div className="col-sm-2 text-center collage">
        <img src="./logo.png" />

        </div>

        <div className="col-sm-5">

        </div>

        </div>
        <div className="row second-row">
        <div className="col-sm-4">
        <img src="./images/mushkpuri2.jpeg" alt="image" height="400px" width="110%" />
        </div>
        <div className="col-sm-4">
        <img src="./images/bigImage.jpg" alt="image" height="400px"/>
        </div>
        <div className="col-sm-4">
        <img src="./images/glamp.jpg" alt="image" height="400px" width="100%"/>
        </div>
        
        </div>

    
        </div>

 
       
        <Footer />
        </div>
    )
}

export default AboutOrangeLoft;