import React, { useState } from "react";
import Navbar from "./Navbar";
import './App.css';
import { Link, useNavigate } from "react-router-dom";
import Footer from "./Footer";

const SignUp = () => {
    const [name , setName] = useState();
    const [email, setEmail] = useState();
    const [password , setPassword] = useState();
    const [confirmPassword , setConfirmPassword] = useState();
    const [error, setError] = useState();

    const [emailError, setEmailError] = useState();

    const [passwordError, setPasswordError] = useState();

    const [passwordRule, setPasswordRule] = useState();

    const passwordFunction = (e) => {
        setPassword(e.target.value);
        if(password.length <= 5)
        setPasswordRule("Password must be atleast 6 characters long");
        if(password.length >= 5)
        setPasswordRule("");
    }
    

    const navigate = useNavigate();


   async function signUp(e){
       e.preventDefault();
        let item = {name , email , password};
    
        // console.warn(item);

       if(password != confirmPassword){
           setError('Password & Confirm password does not match');
       }
       else{
        let result = await fetch("https://dev.techneeq.org/api/register-orange-loft" , {
            method:"POST",
            body:JSON.stringify(item),
            headers:{
                "Content-type":"application/json",
                "Accept":"application/json",
            }
        });


        result = await result.json();
        if(result.errors){
        if(result.errors.email && result.errors.password) {
            setEmailError(result.errors.email);
            setPasswordError(result.errors.password);
     }
        else if(result.errors.password)
        {
            setPasswordError(result.errors.password)
        }
        else if(result.errors.email){
            setEmailError(result.errors.email)
        }
        else {
        console.warn(result.errors.email);
        }
    }
    else {
        localStorage.setItem("user-info", JSON.stringify(result));
        navigate('/signIn');
    }
       }
    }

    return(
        <div>
        <Navbar />
        <div className="container">
        <div className="row">
        <div className="col-sm-12 justify-content-center ">
        
        <form onSubmit={signUp} 
        oninput='password2.setCustomValidity(password2.value != password.value ? "Passwords do not match." : "")'
        >
        
        <div className="row">
        <div className="col-sm-3"></div>

        <div className="col-sm-6 card py-3">
        <h2 className="text-center">Sign Up</h2>
        <div className="form-group">
        <label className="form-label">User Name</label>
        <input type="text"
         name="name"
          placeholder="Enter Name"
          value={name}
          required
          onChange={(e) =>setName(e.target.value) 
         }
          className="form-control" />
        </div>
        <div className="form-group">
        <label className="form-label">User Email</label>
        <input type="email" 
        name="email"
         placeholder="Enter Email" 
         value={email}
          required
         onChange={(e) =>setEmail(e.target.value) }
         className="form-control" />
         <span className="text-danger">{emailError}</span>
        </div>

        <div className="form-group">
        <label className="form-label">Password</label>
        <input type="password"
         name="password"
         value={password}
          required
          placeholder="Enter Password" 
          onChange={passwordFunction}
          className="form-control" />
          <span className="text-danger">{passwordError}</span>
          <span className="text-danger">{passwordRule}</span>
        </div>

        <div className="form-group">
        <label className="form-label">Confirm Password</label>
        <input type="password"
          required
          value={confirmPassword}
        name="password2"
         placeholder="Confirm Password"
         onChange={(e) =>setConfirmPassword(e.target.value) }
          className="form-control" />
          <span className="text-danger">{error}</span>
        </div>

        <div className="form-group">
        <input type="checkbox" name="forgot-password" />
        <label className="form-label ml-2">Already have account? <Link to="/signIn" className="font-weight-bold">Sign In</Link></label>
        </div>

    
        <div className="form-group text-center">
        
        <button className="signIn-btn">Sign Up</button>
        </div>

        </div>

        <div className="col-sm-3">
        </div>

        </div>


        </form>
        </div>
        </div>
        </div>
        <Footer />
        </div>
    );
}

export default SignUp;