import React from "react";
import { useState } from "react";
import './App.css';
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";


const RecommendedStays = () => {
  const [opacity, setOpacity] = useState(0);

  const todayDate = new Date().toISOString().slice(0, 10);

    const date = new Date(Date.now() + ( 3600 * 1000 * 24));
    const tomorrow = date.toISOString().slice(0, 10);
    const [guest, setGuest] = useState(1);
    const [checkIn, setCheckIn] = useState(todayDate);
    const [checkOut, setCheckOut] = useState(tomorrow);
    const [place, setPlace] = useState("");

    const [value, setValue] =  useState([0,90000]);
    const [accommodations, setAccommodation] = useState([]);

    const rangeSelector = (event, newValue) => {
  
        async function getData() {
      
          const res = await axios.get(`https://dev.techneeq.org/api/Accomodations?search=h&invFrom=${checkIn}&invTo=${checkOut}&adults=2&min=${value[0]}&max=${value[1]}`);
        
          setAccommodation(res.data.accommodation);
          
        };
        getData();
        setValue(newValue);
      };
      
      

  const appStyles={
    opacity:`${opacity}`
  }

    return(
        <div>
        <div class="container ">
        <div class="row" >
        <div class="col-sm-12 text-center mt-3">
        <h2 class="section-heading" id="stays">Recommended Stays</h2>
        </div>
        </div>

        <div className="row mb-5">
        
        <div className="col-sm-3 mb-5 mt-4 px-2">
        
        <div id="demo" className="carousel slide" data-ride="carousel" onMouseEnter={() => setOpacity(1)} onMouseLeave={() => setOpacity(0)} >

    
        <ul className="carousel-indicators">
          <li data-target="#demo" data-slide-to="0" className="active"></li>
          <li data-target="#demo" data-slide-to="1"></li>
          <li data-target="#demo" data-slide-to="2"></li>
        </ul>
        
  
        <div className="carousel-inner" >
          <div className="carousel-item active">
            <img src="./images/murree1.jpeg" alt="Naran" className="carousel-inner-img"  />
          </div>
          <div className="carousel-item">
            <img src="./images/murree2.jpeg" alt="Naran" className="carousel-inner-img" />
          </div>
          <div className="carousel-item">
            <img src="./images/murree3.jpeg" alt="Naran" className="carousel-inner-img" />
          </div>
        </div>
        
       
        <a className="carousel-control-prev" href="#demo" data-slide="prev" style={appStyles} >
          <span className="carousel-control-prev-icon"></span>
        </a>
        <a className="carousel-control-next" href="#demo" data-slide="next" style={appStyles} >
          <span className="carousel-control-next-icon"></span>
        </a>
      
        
        </div>
      
        <Link to={`listing/Orangeloft%20Glamp%20park/${checkIn}/${checkOut}/${guest}`} className="link">
        <div className="row">
      <div className="col-sm">
      <span className="stays">Glamp Park</span>
  
      </div>

      <div className="col-sm text-alignment">
      <span className="price">PKR 25,000/night</span>
      </div>
      </div>

      <span className="row">
        <span className="col-sm-12">

        <span className="address">Bansra Gali,Murree</span>
        </span>
        </span>
        </Link>
        
        </div>

        <div className="col-sm-3 mb-5 mt-4 px-2">
        
        <div id="demo2" className="carousel slide" data-ride="carousel" onMouseEnter={() => setOpacity(1)} onMouseLeave={() => setOpacity(0)} >

    
        <ul className="carousel-indicators">
          <li data-target="#demo2" data-slide-to="0" className="active"></li>
          <li data-target="#demo2" data-slide-to="1"></li>
          <li data-target="#demo2" data-slide-to="2"></li>
        </ul>
        
  
        <div className="carousel-inner" >
          <div className="carousel-item active">
            <img src="./images/shogran1.jpeg" alt="Los Angeles" className="carousel-inner-img" />
          </div>
          <div className="carousel-item">
            <img src="./images/shogran2.jpeg" alt="Chicago" className="carousel-inner-img" />
          </div>
          <div className="carousel-item">
            <img src="./images/shogran3.jpeg" alt="New York" className="carousel-inner-img" />
          </div>
        </div>
        
       
        <a className="carousel-control-prev" href="#demo2" data-slide="prev" style={appStyles} >
          <span className="carousel-control-prev-icon"></span>
        </a>
        <a className="carousel-control-next" href="#demo2" data-slide="next" style={appStyles} >
          <span className="carousel-control-next-icon"></span>
        </a>
      </div>
      <Link to={`listing/Orangeloft%20Shogran/${checkIn}/${checkOut}/${guest}`} className="link">
      <div className="row no-gutters">
      <div className="col-sm-7">
      <span className="stays">Shogran, KPK</span>
     
      </div>

      <div className="col-sm-5 text-alignment">
      <span className="price">PKR 12,000/night</span>
      </div>
      </div>
      <span className="row">
        <span className="col-sm-12">

        <span className="address">Shogran Road, KPK</span>
        </span>
        </span>
        </Link>
        </div>
      

        <div className="col-sm-3 mb-5 mt-4 px-2">
        
        <div id="demo3" className="carousel slide" data-ride="carousel" onMouseEnter={() => setOpacity(1)} onMouseLeave={() => setOpacity(0)} >

    
        <ul className="carousel-indicators">
          <li data-target="#demo3" data-slide-to="0" className="active"></li>
          <li data-target="#demo3" data-slide-to="1"></li>
          <li data-target="#demo3" data-slide-to="2"></li>
        </ul>
        
  
        <div className="carousel-inner" >
          <div className="carousel-item active">
            <img src="https://www.rumblerum.com/wp-content/uploads/2019/09/sweet-tooth-3.jpg" alt="Los Angeles" className="carousel-inner-img"  />
          </div>
          <div className="carousel-item">
            <img src="https://www.rumblerum.com/wp-content/uploads/2019/09/sweet-tooth-6.jpg" alt="Chicago" className="carousel-inner-img" />
          </div>
          <div className="carousel-item">
            <img src="https://www.rumblerum.com/wp-content/uploads/2019/09/sweet-tooth-2.jpg" alt="New York" className="carousel-inner-img" />
          </div>
        </div>
        
       
        <a className="carousel-control-prev" href="#demo3" data-slide="prev" style={appStyles} >
          <span className="carousel-control-prev-icon"></span>
        </a>
        <a className="carousel-control-next" href="#demo3" data-slide="next" style={appStyles} >
          <span className="carousel-control-next-icon"></span>
        </a>
      </div>
      <Link to={`listing/Orangeloft%20Attabad%20Lake/${checkIn}/${checkOut}/${guest}`} className="link">
      <div className="row">
      <div className="col-sm-6">
      <span className="stays">Attabad Lake</span>
      
      </div>

      <div className="col-sm-6 text-alignment">
      <span className="price">PKR 12,000/night</span>
      </div>
      </div>
      <span className="row">
        <span className="col-sm-12">

        <span className="address">Attabad Hunza</span>
        </span>
        </span>
        </Link>
        </div>

        <div className="col-sm-3 mb-5 mt-4 px-2">
        
        <div id="demo1" className="carousel slide" data-ride="carousel" onMouseEnter={() => setOpacity(1)} onMouseLeave={() => setOpacity(0)} >

    
        <ul className="carousel-indicators">
          <li data-target="#demo1" data-slide-to="0" className="active"></li>
          <li data-target="#demo1" data-slide-to="1"></li>
          <li data-target="#demo1" data-slide-to="2"></li>
        </ul>
        
  
        <div className="carousel-inner" >
          <div className="carousel-item active">
            <img src="./images/bigImage.jpg" alt="Los Angeles" className="carousel-inner-img"  />
          </div>
          <div className="carousel-item">
            <img src="./images/room2.jpg" alt="Chicago" className="carousel-inner-img" />
          </div>
          <div className="carousel-item">
            <img src="./images/room3.jpg" alt="New York" className="carousel-inner-img" />
          </div>
        </div>
        
       
        <a className="carousel-control-prev" href="#demo1" data-slide="prev" style={appStyles} >
          <span className="carousel-control-prev-icon"></span>
        </a>
        <a className="carousel-control-next" href="#demo1" data-slide="next" style={appStyles} >
          <span className="carousel-control-next-icon"></span>
        </a>
      </div>


      <Link to={`listing/Orangeloft%20Mushkpuri/${checkIn}/${checkOut}/${guest}`} className="link">
      <div className="row">
      <div className="col-sm">
      <span className="stays">Mushkpuri</span>
  
      </div>

      <div className="col-sm-6 text-alignment">
      <span className="price">PKR 10,000/night</span>
      </div>
      </div>

      <span className="row">
        <span className="col-sm-12">

        <span className="address">Sweetooth-dongagali</span>
        </span>
        </span>
        </Link>
        </div>

        

        

        </div>
        </div>
    </div>
    )
}

export default RecommendedStays;