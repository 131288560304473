import {React , useEffect} from 'react';
import Alert from 'react-bootstrap/Alert';
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";

const Thanks = () => {
  let booking = "";
  let bookingId = "";
  if(JSON.parse(localStorage.getItem('bookingData')))  {
     booking = JSON.parse(localStorage.getItem('bookingData'));
    // console.log(JSON.parse(localStorage.getItem('bookingData')));
    bookingId = booking.bookingId;
    console.log(booking.bookingId);
    // bookingId = booking.BookingId;
}

    const navigate = useNavigate();
   
    useEffect(() => {
           setTimeout(function(){
            navigate('/');
            localStorage.clear();
            }, 5000);
     
        window.scrollTo(0, 0);
        
        // navigate('/');
      }, [])

    return (
        <div>
        <Navbar />
       <div className="container">
       <div className="row">

       <div className="col-sm-12 text-center mt-3">
       <Alert variant="success">
       <Alert.Heading>Thank you for Choosing Orange Loft</Alert.Heading>
       <span>Your Booking is Confirmed</span>
       <br />
       <span>Your Booking Id is {bookingId}</span>
     </Alert>
       </div>
       
       </div>
       </div>

        <Footer />
        </div>
    )
}

export default Thanks;