import React from "react";
import './App.css';
import { Link, useNavigate } from "react-router-dom";



function TopCards() {
return (
    <div>
    <div className="container">

    <div className="row mt-5 mb-5">
    
    <div className="col-sm-4 mt-5">
    <div className="card" >
    <Link to="/perfect-trip" className="link">
    <img className="card-img-top1" src="/images/card1.jpg" alt="Card image cap"/>
    <div className="card-body">
      <h5 className="card-title">Tourism in Pakistan</h5>
      <p className="card-text">Pakistan remains a land of thrilling adventure and breathtakingly beautiful nature, from the massive lengths... </p>
      </div>
      </Link>
  </div>
  
    </div>

    <div className="col-sm-4 mt-5">
    <div className="card">
    <img className="card-img-top" src="/images/glamp.jpg" alt="Card image cap" />
    <Link to="/book-with-flexibility" className="link">
    <div className="card-body">
      <h5 className="card-title">Luxury Glamping pods & Huts</h5>
      <p className="card-text">By 2028, the global glamping industry is anticipated to be worth USD 5.41 billion. From 2021 to 2028...</p>
     
    </div>
    </Link>
  </div>
  
    </div>

    <div className="col-sm-4 mt-5">
    <div className="card" >
    <img className="card-img-top" src="/images/card3.jpg" alt="Card image cap" />
    <Link to="/blog-3" className="link">
    <div className="card-body">
      <h5 className="card-title">Sustainable Tourism</h5>
      <p className="card-text">Tourism that respects the environment, community, and culture of the tourism destination is known as...</p>
     
    </div>
    </Link>
  </div>
  
    </div>

    </div>

    </div>

    </div>
);
}

export default TopCards;