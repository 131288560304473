import React, { useState } from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {

    const navigation = useNavigate();

    const [email, setEmail]= useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();

    const [error, setError] = useState();


    async function updatePassword (e) {
        
        e.preventDefault();
        let item = { email , password};

        if(password !== confirmPassword) {
            setError("Password & Confirm Password must match");
        }
        else {
        let result = await fetch(`http://192.168.100.110:8000/api/reset-password?email=${email}&password=${password}` , {
            method:"POST",
            body:JSON.stringify(item),
            headers:{
                "Content-type":"application/json",
                "Accept":"application/json",
            }
        });
    
        result = await result.json();
        console.log(result);
        navigation('/signIn');
    }

    }
    return (
        <div>
        <Navbar />
        <div className="container">
        <div className="row">
        <div className="col-sm-3">
        </div>

        <div className="col-sm-6 card py-5">

        <form onSubmit={updatePassword}>
        <h2 className="text-center">Reset Password</h2>  

        <div className="form-group">
        <label className="form-label">Email:</label>
        <input type="email"
        placeholder="Email"
        onChange={(e) =>setEmail(e.target.value) }
        required
        className="form-control" />
        </div>

        <div className="form-group">
        <label className="form-label">Password:</label>
        <input type="password"
        placeholder="Password"
        required
        onChange={(e) =>setPassword(e.target.value) }
        className="form-control" />
        </div>

        <div className="form-group">
        <label className="form-label">Confirm Password:</label>
        <input type="password"
        placeholder="Confirm Password"
        required
        onChange={(e) =>setConfirmPassword(e.target.value) }
        className="form-control" />
        <span className="text-danger">{error}</span>
        </div>


        <div className="form-group text-center mt-3">
        
        <button className="signIn-btn">Submit</button>
        </div>
        </form>
        </div>

        <div className="col-sm-3">
        </div>
        </div>
        </div>

        <Footer />
        </div>
    )
}

export default ResetPassword;