import logo from './logo.svg';
import './App.css';
import Navbar from './Navbar';
import Home from './Home';
import { Link } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Slider from './Slider';


function App() {
  return (
    <div>
    <Navbar />

    </div>
  );
}

export default App;
