import {React , useState, useEffect} from "react";
import Navbar from "./Navbar";
import "./App.css";
import Footer from "./Footer";
import MyReservations from "./MyReservations";
import { Link, useNavigate } from "react-router-dom";
import FlashMessage from 'react-flash-message';

const MyProfile = () =>{
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
   
const [success, setSuccess]= useState();
    let userEmail = "";
    let userName = "";
    let userId = "";
 
  if(JSON.parse(localStorage.getItem('appState')))  {
        const user = JSON.parse(localStorage.getItem('appState'));
         userName = user.name;
         userEmail = user.item.email;
        
    }
    const [name, setName] = useState(userName);
    const [email, setEmail] = useState(userEmail);

    async function updateProfile(e){
        e.preventDefault();
        if(JSON.parse(localStorage.getItem('appState')))  {
            const user = JSON.parse(localStorage.getItem('appState'));
             userName = user.name;
             userId = user.id;
             userEmail = user.item.email;
            
        }
        let item = [name, email];
        let result = await fetch(`http://192.168.100.110:8000/api/edit/${userId}?name=${name}&email=${email}` , {
            method:"POST",
            body:JSON.stringify(item),
            headers:{
                "Content-type":"application/json",
                "Accept":"application/json",
            }
        });


        result = await result.json();
        localStorage.setItem("user-info", JSON.stringify(result));
        setSuccess('Profile Updated Successfully');
        // console.log(result);
        

    }


    return (
        <div>
        <Navbar />
        <div className="container">
        <div className="row">
        <div className="col-sm-12 text-center">
        <h2>My Profile</h2>
        </div>
        </div>
        <div className="row ">
        <div className="col-sm-12 mt-2">
        <form onSubmit={updateProfile} >
        <div className="row">
        <div className="col-sm-4 text-center">
        <img src="/images/lahore.jpg" alt="profile-pic" className="profile-pic"/>
        <input type="file" name="profile_pic" id="img" style={{display:"none"}}/>
        <div className="row">
        <div className="col-sm-12 mt-2">
        <label for="img" className="upload-new-dp">Upload new </label>
        </div>
        </div>
        </div>

        <div className="col-sm-8 mt-4">
        <div className="row">
        <div className="col-sm-6">
        <div className="form-group">
        <label className="form-label">Name</label>
        {userName !== "" ?
        <input type="text"
          placeholder="Edit Name" 
          className="form-control"
          required
          onChange={(e) => setName(e.target.value)}
          value={name} />
        : 
        <input type="text" name="name" placeholder="Edit Name" required className="form-control"  />
    }
        </div>
        
        </div>

        <div className="col-sm-6">
        <div className="form-group">
        <label className="form-label">Email</label>
        {userEmail !== "" ?
        <input type="email" name="email" placeholder="Edit Email" className="form-control"
        onChange={(e) => setEmail(e.target.value)}
        value={email} /> 
        :
        <input type="email" name="email" placeholder="Edit Email" className="form-control" />
}
        </div>
        
        </div>
        </div>


        <div className="row">
        <div className="col-sm-6">
        <div className="form-group">
        <label className="form-label">Phone No</label>
        <input type="text" name="phone" placeholder="Enter/Add Phone" className="form-control" />
        </div>
        
        </div>

        <div className="col-sm-6">
        <div className="form-group">
        <label className="form-label">Password</label>
        <input type="password" name="password" placeholder="Edit Password"
         className="form-control" />
        </div>
        
        </div>
        </div>

        <div className="row">
        <div className="col-sm-12 text-center mt-3">
        <button className="update-profile-btn">Update</button>
        </div>
        </div>


        <div>
        </div>
        </div>
        </div>
        </form>

        <div>
        </div>
        </div>
        </div>
        </div>


        <MyReservations />

        <Footer />
        </div>
    );
}

export default MyProfile;