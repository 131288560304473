import {React , useState, useEffect} from "react";
import Navbar from "./Navbar";
import PopUp from "./PopUp";
import "./App.css";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import Alert from 'react-bootstrap/Alert'


const Hostpage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  
  
  const [fullName, setFullName] = useState();

  const [date, setDate] = useState();

  const [address, setAddress] = useState();

  const [contact, setContact] = useState();

  const [email, setEmail] = useState();

  const [hotelName, setHotelName] = useState();

const [hotelAddress, setHotelAddress] = useState();

const [hotelOwnerName, setHotelOwnerName] = useState();

const [hotelContact, setHotelContact] = useState();

const [cnic, setCnic] = useState();

const [industryExp, setIndustryExp] = useState();

const [totalSize, setTotalSize] = useState();

const [coveredArea, setCoveredArea] = useState();
 
const [location , setLocation] = useState();

const [totalRooms, setTotalRooms] = useState();

const [roomSize, setRoomSize] = useState();

const [totalBathrooms, setTotalBathrooms] = useState();

const [bathroomSize, setBathroomSize] = useState();

const [totalSuites, setTotalSuites] = useState();

const [suiteSize, setSuiteSize] = useState();

const [kitchenSize, setKitchenSize] = useState();

const [kitchenFixture, setKitchenFixture] = useState();

const [totalHalls, setTotalHalls] = useState();

const [hallSize, setHallSize] = useState();

const [reception, setReception] = useState();

const [laundry, setLaundry] = useState();

const [lounge, setLounge] = useState();

const [restaurant, setRestaurant] = useState();

const [applicantContribution, setApplicantContribution] = useState();

const [standardRoomCharges, setStandardRoomCharges] = useState();

const [standardRoomServices, setStandardRoomServices] = useState();

const [deluxeRoomCharges, setDeluxeRoomCharges] = useState();

const [deluxeRoomServices, setDeluxeRoomServices] = useState();

const [suiteRoomCharges, setSuiteRoomCharges] = useState();

const [suiteRoomServices, setSuiteRoomServices] = useState();

const [facilities, setFacilities] = useState();

const [hotelOwnership, setHotelOwnership] = useState();

const [jointOwnership, setJointOwnership] = useState();

const [hotelBusiness, setHotelBusiness] = useState();

const [terms, setTerms] = useState();

const [allFacilities, setAllFacilities] = useState();

const [successMsg, setSuccessMsg]= useState('');

const navigation = useNavigate();

async function onSubmit(e){
  e.preventDefault();
  let item = { fullName , date, address, contact, email,hotelBusiness, terms,allFacilities, hotelName , hotelAddress , hotelOwnerName, hotelContact, cnic, industryExp, totalSize, coveredArea, location, totalRooms, roomSize, totalBathrooms, bathroomSize, totalSuites, suiteSize, kitchenSize, kitchenFixture , totalHalls, hallSize, reception, laundry, lounge, restaurant, applicantContribution, standardRoomServices, standardRoomCharges, deluxeRoomServices, deluxeRoomCharges, suiteRoomServices, suiteRoomCharges, facilities, hotelOwnership, jointOwnership};
  // console.warn(item);
  setSuccessMsg('Thanks for Choosing Orange Loft!');
   let result = await fetch(`https://dev.techneeq.org/api/become_Host` , {
       method:"POST",
       body:JSON.stringify(item),
       headers:{
           "Content-type":"application/json",
           "Accept":"application/json",
       }
   });
   // console.log('here');
   
   
   result = await result.json();
   if(result){
     setTimeout(function(){
       window.scrollTo(0, 0);
      }, 5000);
    navigation('/');
  }
    

}
return (
     
    <div>
    <Navbar />
    <form onSubmit={onSubmit}>
    <div className="container-fluid">
    <div className="row">
    <div className="col-sm-12 text-center mt-3 orange-background">
    
    <h4>APPLICATION INFROMARTION</h4>
    </div>
    </div>

    </div>

    <div className="container">
    <div className="row mt-3">
   <div className="col-sm-8">
   <label className="form-label">Full Name:</label>
   <input type="text"
   onChange={(e)=>setFullName(e.target.value)}
   name="name" placeholder="Enter Full Name" className="form-control"
   required />
   </div>

   <div className="col-sm-4">
   <label className="form-label">Date:</label>
   <input type="date"
   onChange={(e)=>setDate(e.target.value)}
   name="date" placeholder="Enter Full Name" className="form-control" />
   </div>
    </div>

    <div className="row">
    <div className="col-sm-12 mt-1">
    <label className="form-label">Address:</label>
   <input type="text"
   onChange={(e)=>setAddress(e.target.value)}
   name="address" placeholder="Enter Address"
   required
    className="form-control" />
    </div>
    </div>

    <div className="row">

    <div className="col-sm-6 mt-1">
    <label className="form-label">Contact:</label>
   <input type="text"
   onChange={(e)=>setContact(e.target.value)}
   name="phone" placeholder="Enter Phone"
   required
    className="form-control" />
    </div>

    <div className="col-sm-6 mt-1">
    <label className="form-label">Email:</label>
   <input type="email"
   onChange={(e)=>setEmail(e.target.value)}
   name="email" placeholder="Enter Email"
   required
    className="form-control" />
    </div>
    

    
    </div>


    <div className="row">
    <div className="col-sm-12 mt-1">
    <label className="form-label">Have You done hotel business before?
    </label> <br></br>
    <input type="radio" name="exp" onChange={(e)=> setHotelBusiness(e.target.checked)} className="checkbox" />Yes
    <input type="radio" name="exp" onChange={(e)=> setHotelBusiness(e.target.checked)} className="checkbox"/>No
    </div>
    </div>


    </div>


    <div className="container-fluid">
    <div className="row">
    <div className="col-sm-12 text-center mt-3 orange-background">
    <h4>SITE DETAILS</h4>
    </div>
    </div>

    </div>

    <div className="container">
    <div className="row mt-2">
    <div className="col-sm-6">
    <label className="form-label">Hotel Name:
    </label>
    <input type="text"
    onChange={(e)=>setHotelName(e.target.value)}
    name="hotelName" required placeholder="Enter Hotel Name" className="form-control" />
    </div>

    <div className="col-sm-6">
    <label className="form-label">Address:
    </label>
    <input type="text"
    onChange={(e)=>setHotelAddress(e.target.value)}
    name="hotelAddress" required placeholder="Enter Hotel Address" className="form-control" />
    </div>
    </div>

    <div className="row mt-2">
    <div className="col-sm-12">
    <label className="form-label">Name:
    </label>
    <input type="text"
    onChange={(e)=>setHotelOwnerName(e.target.value)}
    name="name" required placeholder="Enter Name" className="form-control" />
    </div>


    </div>

    <div className="row mt-2">
    <div className="col-sm-6">
    <label className="form-label">Contact No:
    </label>
    <input type="text"
    onChange={(e)=>setHotelContact(e.target.value)}
    name="contactNo" required placeholder="Enter Contact No" className="form-control" />
    </div>

    <div className="col-sm-6">
    <label className="form-label">CNIC:
    </label>
    <input type="text"
    onChange={(e)=>setCnic(e.target.value)}
    name="cnic" required placeholder="Enter CNIC No" className="form-control" />
    </div>
    </div>

    <div className="row mt-2">
    <div className="col-sm-6 mt-1">
    <label className="form-label">Hotel Ownership
    </label>
    <input type="radio"
    onChange={(e)=>setHotelOwnership(e.target.checked)}
    name="hotelOwnership" className="checkbox"/>Owned
    <input type="radio"
    onChange={(e)=>setHotelOwnership(e.target.checked)}
    name="hotelOwnership" className="checkbox"/>Rented
    </div>

    <div className="col-sm-6 mt-1">
    <label className="form-label">Joint Ownership
    </label>
    <input type="radio"
    onChange={(e)=>setJointOwnership(e.target.checked)}
    name="jointOwnership" className="checkbox"/>Yes
    <input type="radio"
    onChange={(e)=>setJointOwnership(e.target.checked)}
    name="jointOwnership" className="checkbox"/>No
    </div>
    </div>


    <div className="row mt-2">
    <div className="col-sm-12">
    <label className="form-label">Terms & Conditions(if rented)</label>
    <textarea class="form-control"
    onChange={(e) => setTerms(e.target.value)}
    rows="5">
    </textarea>
    </div>
    </div>

    </div>


    <div className="container-fluid">
    <div className="row">
    <div className="col-sm-12 text-center mt-3 orange-background">
    <h4>FURTHER INFROMARTION</h4>
    </div>
    </div>

    </div>


    <div className="container">
  <div className="row mt-1">
  <div className="col-sm-12">
  <label className="form-label">How long you have been in hospitality industry?
  </label>
  <input type="text"
  onChange={(e)=>setIndustryExp(e.target.value)}
  required
  name=""
   className="form-control" />
  </div>
  </div>

  <div className="row mt-1">
  <div className="col-sm-6">
  <label className="form-label">Total size of property:
  </label>
  <input type="text"
  onChange={(e)=>setTotalSize(e.target.value)}
  name=""
  required
   className="form-control" />
  </div>

  <div className="col-sm-6">
  <label className="form-label">Covered Area:
  </label>
  <input type="text"
  onChange={(e)=>setCoveredArea(e.target.value)}
  name=""
  required
   className="form-control" />
  </div>
  </div>

  <div className="row mt-1">
  <div className="col-sm-6">
  <label className="form-label">Location:
  </label>
  <input type="text"
  onChange={(e)=>setLocation(e.target.value)}
  name=""
  required
   className="form-control" />
  </div>

  <div className="col-sm-6">
  <label className="form-label">No of rooms:
  </label>
  <input type="number"
  onChange={(e)=>setTotalRooms(e.target.value)}
  name=""
  required
   className="form-control" />
  </div>
  </div>


  <div className="row mt-1">
  <div className="col-sm-6">
  <label className="form-label">Size of Rooms:
  </label>
  <input type="text"
  onChange={(e)=>setRoomSize(e.target.value)}
  name=""
  required
   className="form-control" />
  </div>

  <div className="col-sm-6">
  <label className="form-label">No. of Bathrooms:
  </label>
  <input type="number"
  onChange={(e)=>setTotalBathrooms(e.target.value)}
  name=""
  required
   className="form-control" />
  </div>
  </div>


  <div className="row mt-1">
  <div className="col-sm-6">
  <label className="form-label">Size of Bathrooms:
  </label>
  <input type="text"
  onChange={(e)=>setBathroomSize(e.target.value)}
  name="" className="form-control" />
  </div>

  <div className="col-sm-6">
  <label className="form-label">No. of Suites:
  </label>
  <input type="text"
  onChange={(e)=>setTotalSuites(e.target.value)}
  name="" className="form-control" />
  </div>
  </div>


  <div className="row mt-1">
  <div className="col-sm-6">
  <label className="form-label">Size of Suites:
  </label>
  <input type="text"
  onChange={(e)=>setSuiteSize(e.target.value)}
  name="" className="form-control" />
  </div>

  <div className="col-sm-6">
  <label className="form-label">Kitchen Size:
  </label>
  <input type="text"
  onChange={(e)=>setSuiteSize(e.target.value)}
  name="" className="form-control" />
  </div>
  </div>

  <div className="row mt-1">
  <div className="col-sm-12">
  <label className="form-label">Kitchen Fixture & Fittings:
  </label>
  <input type="text"
  onChange={(e)=>setKitchenFixture(e.target.value)}
  name="" className="form-control" />
  </div>


  </div>

  <div className="row mt-1">
  <div className="col-sm-6">
  <label className="form-label">No. of Halls:
  </label>
  <input type="number"
  onChange={(e)=>setTotalHalls(e.target.value)}
  name="" className="form-control" />
  </div>

  <div className="col-sm-6">
  <label className="form-label">Size of Halls:
  </label>
  <input type="text"
  onChange={(e)=>setHallSize(e.target.value)}
  name="" className="form-control" />
  </div>
  </div>
    </div>


    <div className="container-fluid">
    <div className="row">
    <div className="col-sm-12 text-center mt-3 orange-background">
    <h4>HOTEL FACILITIES</h4>
    </div>
    </div>

    </div>


    <div className="container">
    <div className="row mt-3">
    <div className="col-sm-6">
    <label className="form-label">Recepiton:</label>
    <input type="radio" name="reception" className="checkbox" onChange={(e) => setReception(e.target.checked)} />Yes
    <input type="radio" name="reception" className="checkbox" onChange={(e) => setReception(e.target.checked)} />No
    </div>

    <div className="col-sm-6">
    <label className="form-label">Laundary:</label>
    <input type="radio" className="checkbox" onChange={(e)=>setLaundry(e.target.checked)} />Yes
    <input type="radio" className="checkbox"  onChange={(e)=>setLaundry(e.target.checked)}/>No
    </div>
    </div>

<div className="row">
    <div className="col-sm-6">
    <label className="form-label">Common Lounge:</label>
    <input type="radio" className="checkbox"  onChange={(e)=>setLounge(e.target.checked)}/>Yes
    <input type="radio" className="checkbox"  onChange={(e)=>setLounge(e.target.checked)}/>No
    </div>

    <div className="col-sm-6">
    <label className="form-label">Restaurant:</label>
    <input type="radio" className="checkbox"  onChange={(e)=>setRestaurant(e.target.checked)}/>Yes
    <input type="radio" className="checkbox"  onChange={(e)=>setRestaurant(e.target.checked)}/>No
    </div>
    </div>


    <div className="row mt-1">
    <div className="col-sm-12">
    <label>Contribution by Applicant:
    </label>
    <input type="radio" className="checkbox"  onChange={(e)=>setApplicantContribution(e.target.checked)} />Land
    <input type="radio" className="checkbox" onChange={(e)=>setApplicantContribution(e.target.checked)} />Building
    <input type="radio" className="checkbox" onChange={(e)=>setApplicantContribution(e.target.checked)}/>Renovation
    <input type="radio" className="checkbox" onChange={(e)=>setApplicantContribution(e.target.checked)} />Operation Management
    </div>
    </div>


    <div className="row mt-1">
    <div className="col-sm-6">
    <label className="form-label">Standard Room Charges:</label>
    <input type="text"
    onChange={(e)=>setStandardRoomCharges(e.target.value)}
    name="room_charges" className="form-control" />
    </div>

    <div className="col-sm-6">
    <label className="form-label">Services Included</label>
    <input type="text"
    onChange={(e)=>setStandardRoomServices(e.target.value)}
    name="room_charges" className="form-control" />
    </div>
    </div>

    <div className="row mt-1">
    <div className="col-sm-6">
    <label className="form-label">Deluxe Room Charges:</label>
    <input type="text"
    onChange={(e)=>setDeluxeRoomCharges(e.target.value)}
    name="room_charges" className="form-control" />
    </div>

    <div className="col-sm-6">
    <label className="form-label">Services Included</label>
    <input type="text"
    onChange={(e)=>setDeluxeRoomServices(e.target.value)}
    name="room_charges" className="form-control" />
    </div>
    </div>

    <div className="row mt-1">
    <div className="col-sm-6">
    <label className="form-label">Suite Room Charges:</label>
    <input type="text"
    onChange={(e)=>setSuiteRoomCharges(e.target.value)}
    name="room_charges" className="form-control" />
    </div>

    <div className="col-sm-6">
    <label className="form-label">Services Included</label>
    <input type="text"
    onChange={(e)=>setSuiteRoomServices(e.target.value)}
    name="room_charges" className="form-control" />
    </div>
    </div>


    <div className="row mt-1">
    <div className="col-sm-12">
    <label>Overall facilities provided by hotel management:</label>
    <textarea className="form-control"
    onChange={(e)=>setAllFacilities(e.target.value)}
    rows="4">
    </textarea>
    </div>
    </div>

    </div>


    <div className="container-fluid">
    <div className="row">
    <div className="col-sm-12 text-center mt-3 orange-background">
    <h4>NON-DISCLOSRE AGREEMENT AND SIGNATURE</h4>
    </div>
    </div>

    </div>

    <div className="container">
    <div className="row mt-3">
    <div className="col-sm-12 text-justify">
    <p>The personal information you provide as part of your application may be 
    shared with and between our affiliate brands. In addition, we may share your 
    personal information with companies affiliated with us, vendors and partners 
    as necessary for those companies, vendors and partners to perform business 
    functions, such as fulfilling orders, assisting with promotions, providing 
    marketing or advertising services, providing technical services for our website, 
    etc. Except as described in this privacy policy, Orange Loft® does not sell, 
    transfer, or disclose personal information to third parties outside the Orange 
    Loft® brand portfolio. Orange Loft® reserves the right to use or disclose any 
    information as needed to comply with any law, regulation, court order or legal 
    request, to cooperate in any law enforcement investigation or in connection 
    with a sale or merger of all or part of Orange Loft®, its parent or affiliates. 
    Except as stated in this privacy policy, all correspondence from you with 
    Orange Loft®, it's parents or affiliates, is considered non-confidential except to 
    the extent that such correspondence is subject to a separate confidentiality or 
    non-disclosure agreement. If you have any questions about this privacy policy, 
    please feel free to contact us</p>
    <p>I  certify that the information I have provided to Orange Loft® is true and 
    correct. I authorize Orange Loft® to verify the information I have provided on 
    this and any attached forms including, but not limited to, acquiring a credit 
    verification report from a credit agency, acquiring a back ground check from a 
    registered state agency, and to contact my named references and other 
    sources for information about me. I hold Orange Loft®, its affiliates, agents and 
    employees harmless for any damages or liability arising either from the receipt 
    or use of any information obtained through these sources I have provided.</p>
    <p className="font-weight-bold">Once the application is approved according the terms and condition then 
    you receive The Franchise Disclosure Document.</p>
    </div>
    </div>
    </div>

<div className="container">
<div className="row">
<div className="col-md-12 text-center">
<button type="submit" className="boder btn-book" >Submit</button>
<br />
{successMsg &&
  <Alert variant="success">
       <Alert.Heading>Thank you for Choosing Orange Loft</Alert.Heading>
       <span>Our Administration team will contact you soon</span>
       <br />
       
     </Alert>
        }
</div>
</div>
</div>
    </form>
    <Footer />
    
    
    </div>
 
        
 
    
    )
}

export default Hostpage;