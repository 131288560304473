import React from "react";
import "./App.css";

const VrTour = () =>{
    const iframe = "https://www.mapalytics.org/vr/Orange_Loft_Bansra.html";
    const src="https://www.mapalytics.org/vr/Orange_Loft_Bansra.html";
    return(
        <div>
        <iframe src={src} allow="fullscreen" className="iframe-style" />
        </div>
    )
}

export default VrTour;