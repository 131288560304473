import {React , useState , useEffect} from 'react';
import './App.css';
import Navbar from './Navbar';
import { Link, useParams, useNavigate } from "react-router-dom";
import Footer from './Footer';
import Alert from 'react-bootstrap/Alert'

const BookNow = () => {
    let {accommodation_Id, checkIn, checkOut, accommodation, address,guest,nights ,  rate, rooms, roomtype , rateplan } = useParams();

    console.log(accommodation_Id, checkIn, checkOut, accommodation, address,guest,nights , rate , rooms, roomtype , rateplan )
    const sidebarCheckIn = new Date(checkIn).toLocaleString('en-us',{day:'numeric', month:'long', year:'numeric' });
    const sidebarCheckOut = new Date(checkOut).toLocaleString('en-us',{month:'long', day:'numeric', year:'numeric'});
      const [imgurl , setimgurl] = useState("/images/murree3.jpeg");
    
    let nightText = "";
    

     useEffect(() => {    
             if(accommodation ==  "Orangeloft Glamp park"){
       
        setimgurl("/images/murree_hotel.jpeg");
      
    
    }
        else if(accommodation == "Orangeloft Mushkpuri"){   
     
      
       setimgurl("/images/muskpuri.jpeg");
     
    
    }

        else if(accommodation == "Orangeloft Wild Life Camping"){   
     
     
       setimgurl("/images/wildlifecamping.jpeg");
     
    
    }

       else if(accommodation == "Orangeloft Naraan"){   
     
     
       setimgurl("/images/Naran.jpeg");
    
    }
       else if(accommodation == "Orangeloft Walled City Lahore"){   
    
       setimgurl("/images/wallet_city_lahoure.jpeg");
      
    
    }

       else if(accommodation == "Orangeloft Frontier"){   
     
      
        setimgurl("/images/malam_jabba_hotel.jpeg");
      

    
    }

       else if(accommodation == "Orangeloft Mahodand Lake")
    {
    
         setimgurl("/images/mahodad_lake.jpeg");
   

    }

    else if(accommodation == "Orangeloft Kalabagh"){
        setimgurl("/images/kala_hotel.PNG")

    }
    else if(accommodation == "Orangeloft Shogran"){
        setimgurl("/images/shogran_hotel.PNG")

    }
    else if(accommodation == "Orangeloft Attabad Lake"){
        setimgurl("/images/attabad_hotel.JPG")

    }
    else if(accommodation == "Orangeloft Lower Kachura Lake"){
        setimgurl("/images/skardu_hotel_lower.JPG")

    }

   
       },[]);

    
 
 
    
    if(nights == 1)
    {
        nightText = "Night";
    }
    else {
        nightText = "Nights";
    }
    let totalRooms = rate/rooms;
    totalRooms = Math.trunc(totalRooms, '');
    let roomsText = "";
    if(totalRooms === 1){
        roomsText = "Room";
    }
    else {
        roomsText = "Rooms";
    }
    // console.log(totalRooms)
    const [roomsPrice , setroomsPrice] = useState(); 
    const [total ,settotal] = useState(); 
    const [gst , setgst ] = useState(); 
    const [totalPrice ,settotalPrice] = useState(); 
   const [first_name, setfirst_name] = useState();
   const [last_name, setlast_name] = useState();
   const [email, setemail] = useState();
   const [phone, setphone] = useState();
   const [city, setcity] = useState();
   const [guest_address , setguest_address] = useState();
   const [postal_code, setpostal_code] = useState();
   const [country , setcountry] = useState();
   const [guest_request , setguest_request] = useState();
   const [cnic , setcnic] = useState();
   const [password_no , setpassword_no] = useState();
   const [btndisable , setbtndisable] = useState(false);
   
 

       useEffect(() => {

   console.log(accommodation_Id);
 if(accommodation_Id == 98){



        let totalPrices = nights*rate;
        // console.log(totalPrices);
        setroomsPrice(totalPrices);
        let gsts = (totalPrices*16)/100;
        let totals = totalPrices+gsts;
        settotal(totals);
         setgst(gsts);
        settotalPrice(totalPrices);

    }
    else if(accommodation_Id == 38){
        let totalPrices = nights*rate;
        // console.log(totalPrices);
        setroomsPrice(totalPrices);
        let gsts = (totalPrices*16)/100;
        let totals = totalPrices+gsts;
        settotal(totals);
         setgst(gsts);
        settotalPrice(totalPrices);

    }
    else if (accommodation_Id == 122){

        let totalPrices = nights*rate;
        // console.log(totalPrices);
        setroomsPrice(totalPrices);
        let gsts = (totalPrices*15)/100;
        let totals = totalPrices+gsts;
        settotal(totals);
         setgst(gsts);
        settotalPrice(totalPrices);

    }
    else if (accommodation_Id == 126){

        let totalPrices = nights*rate;
        // console.log(totalPrices);
        setroomsPrice(totalPrices);
        let gsts = (totalPrices*15)/100;
        let totals = totalPrices+gsts;
        settotal(totals);
         setgst(gsts);
        settotalPrice(totalPrices);

    }
    else if (accommodation_Id == 150){
        let totalPrices = nights*rate;
        // console.log(totalPrices);
        setroomsPrice(totalPrices);
        let gsts = (totalPrices*15)/100;
        let totals = totalPrices+gsts;
        settotal(totals);
         setgst(gsts);
        settotalPrice(totalPrices);


    }
    else if (accommodation_Id == 86){

        let totalPrices = nights*rate;
        // console.log(totalPrices);
        setroomsPrice(totalPrices);
        let gsts = (totalPrices*15)/100;
        let totals = totalPrices+gsts;
        settotal(totals);
         setgst(gsts);
        settotalPrice(totalPrices);

    }
    else if(accommodation_Id == 134){

        let totalPrices = nights*rate;
        // console.log(totalPrices);
        setroomsPrice(totalPrices);
        let gsts = (totalPrices*15)/100;
        let totals = totalPrices+gsts;
        settotal(totals);
         setgst(gsts);
        settotalPrice(totalPrices);

    }

    else if (accommodation_Id == 86){

        let totalPrices = nights*rate;
        // console.log(totalPrices);
        setroomsPrice(totalPrices);
        let gsts = (totalPrices*15)/100;
        let totals = totalPrices+gsts;
        settotal(totals);
         setgst(gsts);
        settotalPrice(totalPrices);

    }
    else if (accommodation_Id == 134){

        let totalPrices = nights*rate;
        // console.log(totalPrices);
        setroomsPrice(totalPrices);
        let gsts = (totalPrices*15)/100;
        let totals = totalPrices+gsts;
        settotal(totals);
         setgst(gsts);
        settotalPrice(totalPrices);

    }
    else if (accommodation_Id == 90){
        let totalPrices = nights*rate;
        // console.log(totalPrices);
        setroomsPrice(totalPrices);
        let gsts = (totalPrices*15)/100;
        let totals = totalPrices+gsts;
        settotal(totals);
         setgst(gsts);
        settotalPrice(totalPrices);

    }
    else if(accommodation_Id == 142){

        let totalPrices = nights*rate;
        // console.log(totalPrices);
        setroomsPrice(totalPrices);
        let gsts = 0 ;
        let totals = totalPrices+0;
        settotal(totals);
         setgst(gsts);
        settotalPrice(totalPrices);


    }
    else if (accommodation_Id == 138){

        let totalPrices = nights*rate;
        // console.log(totalPrices);
        setroomsPrice(totalPrices);
        let gsts = 0 ;
        let totals = totalPrices+gsts;
        settotal(totals);
         setgst(gsts);
        settotalPrice(totalPrices);
    }
    else{

        let totalPrices = nights*rate;
        // console.log(totalPrices);
        setroomsPrice(totalPrices);
        let gsts = (totalPrices*16)/100;
        let totals = totalPrices+gsts;
        settotal(totals);
         setgst(gsts);
        settotalPrice(totalPrices);

    }
       
      } , []);

    


    const [error, setError] = useState();
    const [count , setcount] = useState(0);

    const [newCheckIn, setNewCheckIn] = useState();

    const navigate = useNavigate();

  
    async function submitForm(e){
        e.preventDefault();
        setcount(1);
        let item = {accommodation_Id, checkIn, checkOut , guest , totalRooms , nights ,roomsPrice, gst, total, first_name ,last_name , email,phone ,  city,guest_address,postal_code,country,guest_request,cnic,password_no,  roomtype, rooms, rateplan};
        console.log(item);
        
        
         let result = await fetch("https://app.techneeq.org/api/bookings-create",{
             method:"POST",
             body:JSON.stringify(item),
             headers:{
                 "Content-type":"application/json",
                 "Accept":"application/json",
             }
         });
      
         result = await result.json();
         if(result.booking)
         {
        console.log(result.booking[0].BookingId_CM);
        let bookingData = {
            bookingId: result.booking[0].BookingId_CM,
        };
        localStorage["bookingData"] = JSON.stringify(bookingData);
         navigate('/thanks');
         }
         else {
            setError('Error while booking');
            console.log("here");
         }

        }


    return (
        <div>
        <Navbar />

        <div className="container">
        <div className="row">
        <div className="col-sm-12 card mt-4 p-0">

      
        <div className="row ">
        <div className="col-sm-12">
        
        <div className="row">
        <div className="col-sm-3">
        <img src={imgurl} width="100%" height="200" className="book-now-img"/>
        </div>

        <div className="col-sm-9 pt-3 px-2 mob-padding">
        <h5>{accommodation}</h5>
        <p>{address}</p>
        <span className="text-orange font-weight-bold text-uppercase">Cancellation Policy:</span><br />
        <span>Cancellation Policy: Free cancellation with full refund given up to 24 hours before arrival.</span>
       
        </div>
        </div>
        <hr />
        </div>
        </div>

        <div className="row px-5 mob-hide-info">
        <div className="col-sm-3">
        <span className="text-orange font-weight-bold" style={{borderBottom:"1px solid orange"}}>Check In: </span>
        </div>

        <div className="col-sm-3">
        <span className="text-orange font-weight-bold" style={{borderBottom:"1px solid orange"}}>Check Out:</span>
        </div>

        <div className="col-sm-3">
        <span className="text-orange font-weight-bold" style={{borderBottom:"1px solid orange"}}>Guests:</span>
        </div>

        <div className="col-sm-3">
        <span className="text-orange font-weight-bold" style={{borderBottom:"1px solid orange"}}>Total Stay:</span>
        </div>
        

        </div>

        <div className="row mob-room-details-section px-5">
        <span className="text-orange  font-weight-bold">Check In: </span>
        <span className="px-2">{sidebarCheckIn}
        </span>
    
        </div>

        <div className="row mob-room-details-section px-5">
        <span className="text-orange  font-weight-bold">Check Out: </span>
        <span className="px-2"> {sidebarCheckOut}</span>
        <br />
        </div>

        <div className="row mob-room-details-section px-5">
        <span className="text-orange font-weight-bold">Guests: </span>
        <span className="px-2 "> {guest}</span>
        <br />
        </div>

        <div className="row mob-room-details-section px-5 pb-3">
        <span className="text-orange font-weight-bold">Total Stay: </span>
        <span className="px-2 "> {nights} {nightText}</span>
        <br />
        </div>



        <div className="row px-5 pb-3  mob-hide-info">
        <div className="col-sm-3">
        <span > {sidebarCheckIn}</span>
        <br />

        </div>

        <div className="col-sm-3">
        <span >{sidebarCheckOut}</span>
        </div>

        <div className="col-sm-3">
        <span >{guest}</span>
        </div>

        <div className="col-sm-3">
        <span >{nights} {nightText}</span>
        </div>

        </div>


        </div>



        </div>

    


        </div>


        
        <div className="container">
        <div className="row">
        <div className="col-sm-9 py-5">
        <h4 >Enter your details please</h4>
        <form onSubmit={submitForm}>

        <div className="row">

        <div className="col-sm-6 ">
        <label className="form-label mt-1">First name*</label>
        <input type="text" 
        value={first_name}
        onChange={(e) => setfirst_name(e.target.value)}
        className="form-control" 
        placeholder="Enter First Name"
        required
        />
           <label className="form-label mt-1">Last name*</label>
        <input type="text"
        value={last_name}
        className="form-control"
        onChange={(e) => setlast_name(e.target.value)}
        placeholder="Enter Last Name"
        required
         />
      
       
        </div>

        <div className="col-sm-6">

        <label className="form-label mt-1">Address*</label>
        <input type="text"
        value={guest_address}
        onChange={(e) => setguest_address(e.target.value)}
        className="form-control" placeholder="Enter Address"
        required
         />
       

        <label className="form-label mt-1">City*</label>
        <input type="text"
        value={city}
        onChange={(e) => setcity(e.target.value)}
        className="form-control" placeholder="Enter City"
        required
         />
        </div>

        </div>







        <div className="row">
        <div className="col-sm-6 ">
        <label className="form-label mt-1">Phone*</label>
        <input type="number" 
        value={phone}
        onChange={(e) => setphone(e.target.value)}
        className="form-control" 
        placeholder="Enter Phone"
        min="1"
        required
        />
        <label className="form-label mt-1">Email*</label>
        <input type="email"
        value={email}
        className="form-control"
        onChange={(e) => setemail(e.target.value)}
        placeholder="Enter Email"
        required />
        </div>

        <div className="col-sm-6">
        <label className="form-label mt-1">Country*</label>
        <input type="text"
        value={country}
        onChange={(e) => setcountry(e.target.value)}
        className="form-control" placeholder="Enter Country"
        required />

        <label className="form-label mt-1">CNIC number*</label>
        <input type="number"
        value={cnic}
        onChange={(e) => setcnic(e.target.value)}
        className="form-control" placeholder="Enter CNIC Number"
        min="1"
        required />
        </div>
        </div>
        <div className="row">
        <div className="col-sm-6 ">
        <label className="form-label mt-1">Passport number</label>
        <input type="number" 
        value={password_no}
        onChange={(e) => setpassword_no(e.target.value)}
        className="form-control" 
        placeholder="Enter Password Number"
        min="1"

        />
        <label className="form-label mt-1">Notes</label>
        <input type="text"
        value={guest_request}
        className="form-control"
        onChange={(e) => setguest_request(e.target.value)}
        placeholder="Enter Notes" />
        </div>

     
        </div>









        <div className="row">
        <div className="col-sm-12 text-center mt-5">
          {
            count == 0  ?
         <button type="submit" className="booking-btn" >Submit</button>
         :
         <button disabled={true} className="booking-btn" >Submit</button>
          }
        </div>
        </div>
        </form>

        </div>


        <div className="col-sm-3 card mt-4 py-4 px-4 h-50 d-inline-block">
        <h5>Payment Amount</h5>
        <div className="row no-gutters mt-2" style={{fontSize:"14px"}}>
        <div className="col-sm-7" >
        <span>{1} room/s</span><br />
        <span>G.S.T excluded</span>
        </div>

        <div className="col-sm-5 text-right">
        <span className="font-weight-bold">PKR {totalPrice}</span><br />
        <span className="font-weight-bold">PKR {gst}</span>
        </div>
        </div>
        <hr />

        <div className="row text-orange" style={{fontSize:"14px;"}}>
        <div className="col-sm-6">
        <span>Total Price</span>
        </div>

        <div className="col-sm-6 text-right">
        <span className="font-weight-bold">PKR {total}</span>
        </div>
        </div>

        
        <div className="row">
        <div className="col-sm-12 mt-4">
        <span className="text-orange font-weight-bold">Cancellation Policy:</span><br />
        <span>Cancellation Policy: Free cancellation with full refund given up to 24 hours before arrival.</span>
        </div>
        </div>
        </div>

        
        </div>
        </div>

        <Footer />
        </div>
    )
}

export default BookNow;