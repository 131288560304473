import { React , useEffect } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import "./App.css";

const Blog3 = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(
        <div>
        <Navbar />

        <div className="container-fluid">
        <div className="row">
        <div className="col-sm-12">
        <img src="/images/card3.jpg" alt="Blog-image" className="blog-img2" />
        </div>
        </div>
        </div>


        <div className="container">
        <div className="row">
        <div className="col-sm-12 mt-5">
        <h2>We've got your back</h2>
        <p>
        Tourism that respects the environment, community, and culture of the tourism destination is known as responsible tourism. Its goal is to mitigate the negative consequences of irresponsible mass tourism. This sort of tourism attempts to promote long-term tourism growth that safeguards the interests of local communities and their heritage assets.
<br /><br />
Environmental sustainability, social sustainability, and economic sustainability are the three pillars of sustainable tourism (sometimes referred to as planet, people and profits). Environmental sustainability can be achieved by measures such as trash reduction, the elimination of single-use plastics, and the reduction of overall carbon emissions. Natural surroundings such as forests and waterways, as well as man-made environmental artifacts such as historic structures, architecture, and artworks, must be protected.
<br /><br />

Financial profitability is important for economic sustainability, but not at the expense of the other two pillars (environmental and social). A corporation cannot be sustainable if it is not lucrative, but profit must never come at the price of environmental and social considerations.
<br /><br />

Social sustainability is more difficult to describe than environmental sustainability, but it is essentially the concept of conducting business in the best interests of your employees, stakeholders, partners, and communities in which you operate. We must acknowledge that all tourism has an influence on communities, and it is our obligation to mitigate or eliminate negative consequences while increasing favorable ones.
<br /><br />

Tourism sustainability attempts to protect resources for future generations. Revenues from tourism help to support local economies. It boosts industries like agriculture and the hotel industry by creating job possibilities. The various benefits that tourism provides to the current generation will be lost to future generations if sustainability is not prioritized in the tourism business.

        </p>
        
        </div>
        </div>
        </div>

        <Footer />
        </div>
    )
}

export default Blog3;