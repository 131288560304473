
import {React , useState} from 'react';
import HoverImage from "react-hover-image";
import { GoogleMap, LoadScript,Circle, OverlayView ,ScriptLoaded,InfoWindow,Rectangle } from '@react-google-maps/api';




const mapContainerStyle = {
    height: "400px",
    width: "100%",
    border:"none",
    borderRadius:"13px"
  }
  
  const center = {
    lat: 34.061528003117765,
    lng: 73.43010932612995
  }


  
  const options = {
    backgroundColor:"white !important",
    strokeColor: 'orange',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: 'orange',
    fillOpacity: 1,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 3000,
    zIndex: 1
  }
  

  
  const onUnmount = circle => {
    console.log('Circle onUnmount circle: ', circle)
  }
 
  
  const bounds = {
    north: 34.061528003117765,
    south: 34.0926800,
    east: 73.43010932612995,
    west: 73.4001083,

  }

  const bounds1 = {
    north: 33.906565345941026,
    south: 33.9375653,
    east: 73.39474410024336,
    west: 73.364744,

  }

  const position = { 
    lat: 34.061528003117765,
    lng: 73.43010932612995, }
  

  
  const divStyle = {
   borderRadius:"100px",
   height:"30px",
   width:"30px",
   padding:"0px",
   backgroundColor:"orange"
  }
  
  
  const onLoad = rectangle => {
    console.log('rectangle: ', rectangle)
  }


  

function Map2() {
    const [state, setState] = useState(false);

    const mouseOver = () => {
        
        setState(true);
    }

    const mouseOut =() =>  {
        setState(false);
    }

  
    return (
      <LoadScript
      googleMapsApiKey="AIzaSyDHwdcWVGV2HKr5fHwkmehGMYCp-mXvqG8"
    >
    <GoogleMap
    id="circle-example"
    mapContainerStyle={mapContainerStyle}
    zoom={10}
    center={center}
  >
    <Circle
      // optional
      onLoad={onLoad}
      // optional
      onUnmount={onUnmount}
      // required
      center={center}
      // required
      options={options}
    >
    <img src="https://orangeloft.techneeq.org/logo.png" />
    </Circle>
  </GoogleMap>
        </LoadScript>
    
  )
  
}

export default Map2;