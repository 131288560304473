import React, { useState, useCallback, useEffect } from "react";
import Navbar from "./Navbar";
import './App.css';
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import ExpandLessSharpIcon from '@mui/icons-material/ExpandLessSharp';
import Footer from "./Footer";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import URLSearchParams from "use-url-search-params";
import Slider from '@mui/material/Slider';



const BookNow = () => {
    const todayDate = new Date().toISOString().slice(0, 10);

    const date = new Date(Date.now() + ( 3600 * 1000 * 24));
    const tomorrow = date.toISOString().slice(0, 10);
    const checkIn = todayDate;
    const checkOut = tomorrow;
    const place="";
    const guest=1;
    
  const checIn = "11-4-2022";
  const checOut = "15-4-2022";
 const sidebarCheckIn = new Date(checkIn).toLocaleString('en-us',{month:'short', day:'numeric'});
 const sidebarCheckOut = new Date(checkOut).toLocaleString('en-us',{month:'short', day:'numeric'});


  const [accommodations, setAccommodation] = useState([]);


  const [nights, setNights] = useState();

  const [value, setValue] =  useState([0,90000]);

 
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {

  
    async function getData() {
      window.scrollTo(0, 0);
      setIsLoading(true);
      const res = await axios.get(`https://app.techneeq.org/api/Accomodations?search=all&invFrom=${checkIn}&invTo=${checkOut}&adults=${guest}&min=${value[0]}&max=${value[1]}`);
     
      console.log(res);
      // console.log(res.data.totalStay);
      setNights(res.data.totalStay);
     
      // console.log(res.data.accommodation[0].room_types[0].MaxPersons);
      setAccommodation(res.data.accommodation);
     
      setIsLoading(false);
     
    };
    getData();
  
},[]);



const rangeSelector = (event, newValue) => {
  
  async function getData() {

    const res = await axios.get(`https://app.techneeq.org/api/Accomodations?search=all&invFrom=${checkIn}&invTo=${checkOut}&adults=2&min=${value[0]}&max=${value[1]}`);
  
    setAccommodation(res.data.accommodation);
    
  };
  getData();
  setValue(newValue);
};


// const properties = Object.keys(posts).length;

    const upArrow = <ExpandLessSharpIcon />;
    const downArrow = <ExpandMoreSharpIcon />;
    const [open, setOpen] = useState(upArrow);
    const [state, setState] = useState(downArrow);
    const [opacity, setOpacity] = useState(0);

    const appStyles={
      opacity:`${opacity}`
    }


    const toggle =() =>{
        if(open === upArrow){
            setOpen(downArrow);
        }
        else {
            setOpen(upArrow);
        }
      
    }

    const  [toggleHeart, setToggleHeart] = useState(false);
    
    const changeColor = useCallback(() =>{
     setToggleHeart(!toggleHeart)
    },[]);

    let guestText = "";
    if(guest == 1){
      guestText = "adult";
    }
    else {
      guestText = "adults";
    }



    return (
        <div>
        <Navbar />

        <div className="container listing-container">

        <div className="row mt-5">

        <div className="col-sm-3 ">
        <h2>{place}</h2>
        <p>{sidebarCheckIn} - {sidebarCheckOut} <span className="dot">.</span>{guest} {guestText}</p>
        {/* <div className="btn-image"><Link to={`/MapProperties/${checkIn}/${checkOut}/${guest}`} className="nav-link1">
        <button className="btn btn-light">Go to map</button></Link>
        </div> */}

        
        <p className="font-weight-bold mt-1">Price</p>
  
      <Slider
      value={value}
      min={0}
      max={100000}
      onChange={rangeSelector}
      valueLabelDisplay="auto"
    />
   
        <p className="flex-container"><span>Rs.{value[0]}</span><span className="max-price">Rs.{value[1]}</span></p>
        <p className="flex-container font-weight-bold mt-4"><span>Property Type</span><span className="max-price"><span onClick={toggle}>{open} </span></span></p>
        <div >
        <label className="container"><span className="span-class">Hotel</span>
        <input type="checkbox"  className="input" />
        <span className="checkmark"></span>
      </label>
      <label className="container"><span className="span-class">Serviced Apartment</span>
      <input type="checkbox" className="input"/>
      <span className="checkmark"></span>
       
      </label>
      <label className="container"><span className="span-class">Homestay</span>
        <input type="checkbox" className="input" />
        <span className="checkmark"></span>
      </label>
      <label class="container"><span className="span-class">Hostel</span>
        <input type="checkbox" className="input"/>
        <span className="checkmark"></span>
      </label>
      </div>
        </div>

        <div className="col-sm-9 mt-3">
        
        <p style={{color:'white'}}><span className="font-weight-bold">3</span> out of 200 properties</p>
        {isLoading ? 
         <div className="col-sm-12 text-center">
         <div className="spinner-border spinner-color text-center" role="status">
         <span className="sr-only">Loading...</span>
       </div>
         </div>
          :
          accommodations.map((accommodation) => {
         
           
            return (
              <div>
          
              {accommodation.room_types.map((room_type, i) => {
             
                return (
                  <div>
                  {i == 0 && 
                    <div className="card mt-4" >
                    <div className="row no-gutters">
            
                    <div className="col-sm-4 mt-3 mb-3 ml-3" >
                    <div id="demo" className="carousel slide" data-ride="carousel" onMouseEnter={() => setOpacity(1)} onMouseLeave={() => setOpacity(0)}>
            
                
                    <ul className="carousel-indicators">
                      <li data-target="#demo" data-slide-to="0" className="active"></li>
                      <li data-target="#demo" data-slide-to="1"></li>
                      <li data-target="#demo" data-slide-to="2"></li>
                    </ul>
                    
               
                     
                    <div className="carousel-inner" >
                      <div className="carousel-item active carousel-item1 ">
                        <img src={`https://dev.techneeq.org/storage/${accommodation.images[0].ImageURL}`} alt="Hotel Image" className="img" style={{height:"210px"}}  />
                      </div>
                     
                    </div>
                      
                    
                   
                    <a className="carousel-control-prev" href="#demo" data-slide="prev" style={appStyles} >
                      <span className="carousel-control-prev-icon"></span>
                    </a>
                    <a className="carousel-control-next" href="#demo" data-slide="next" style={appStyles} >
                      <span className="carousel-control-next-icon"></span>
                    </a>
                  
                    
                    
                    </div>
            
                    </div>
                     
                    <div className="col-sm-7 mt-4 ml-3 mr-0">
                    <div className="row">
                    <div className="col-sm-12">
                    <div className="row">
                    <div className="col-sm-11">
                   
                    <span className='repo-text'> </span>
                    {accommodation.room_types.map((room_type, i) => {
                      return (
                        <div>
                        {i <= 0 && 
                          
                          <Link to={`/singleProperty/${accommodation.AccommodationId}/${checkIn}/${checkOut}/${accommodation.AccommodationName}/${accommodation.rate_plans[0].DefaultRates}/${accommodation.Address}/${guest}/${nights}`} className="nav-link1">
                        <h5>{accommodation.AccommodationName}</h5>
                        <h5>{`${accommodation.Address}`}</h5>
                        </Link>
                      }
                        
                     </div>
                      ); }
                     )}
                   
                  
                  
                    </div>
                    <div className="col-sm-1">
                    <i className={
                        toggleHeart ? 'heart active far fa-heart' : 'heart far fa-heart'
                      } onClick={changeColor}></i>
                    </div>
                    </div>
                    
                    {accommodation.room_types.map((room_type, i) => {
                      return (
                        <div>
                        {i <= 0 && <p>{room_type.MaxPersons} persons {room_type.NoOfBeds} Bedrooms {room_type.BathRoomDetail} Bathrooms</p>}
                        
                     </div>
                      ); }
                     )}
                    <br />
                    </div>
                    
                
                    </div>
            
                    <div className="row ">
            
                    <div className="col-sm-1 mr-1">
                    <h4 className="rating text-white">4.4</h4>
                    </div>
            
                    <div className="col-sm-3 excellentleft">
                    <p><span className="font-weight-bold ">Excellent </span><br /><span className="price">226 Reviews</span></p>
                    
                    </div>
            
                    <div className="col-sm-3">
                    <p><span className="font-weight-bold">Location </span><br /><a href="" className="show-map">Show on map</a></p></div>
            
                    <div className="col-sm-4 px-0 textright ">
                    {accommodation.rate_plans.map((rate_plan, i) => {
                      return (
                        <div className="">
                        {i <= 0 && <p><span className="font-weight-bold ">{rate_plan.DefaultRates}.PKR </span><span className="price">/night<br />All taxes are excluded</span></p>}
                        
                     </div>
                      ); }
                     )}
                    
                   
                    </div>
                    </div>
            
                    </div>
            
                    
                    </div>
                    
                    </div>
                  }
                  
                  
               </div>
                ); }
               )}  
  
                </div>
                );
              })}
        
        
        
        
     
       

     


        



      </div></div></div>

      <Footer />
       

      </div>
        
    );
}
export default BookNow;