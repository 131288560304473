import React from 'react';
import ReactDOM from 'react-dom';
// import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from './Home';
import { Navbar } from 'react-bootstrap';
import Slider from './Slider';
import 'bootstrap/dist/css/bootstrap.css';
import Listing from './Listing';
import OpenMap from './OpenMap';
import SingleProperty from './SingleProperty';
import Login from './Login';
import SignUp from './SignUp';
import Careers from "./Careers";
import JobApply from "./JobApply";
import MyProfile from "./MyProfile";
import HostPage from "./HostPage";
import AboutOrangeLoft from "./AboutOrangeLoft";
import ForgetPassword from "./ForgetPassword";
import ResetPassword from "./ResetPassword";
import VrTour from "./VrTour";
import Rooms from "./Rooms";
import BookNow from "./BookNow";
import Thanks from "./Thanks";
import Blog1 from "./Blog1";
import Blog2 from "./Blog2";
import Blog3 from "./Blog3";
import BookNowPage from "./BookNowPage";
import RV from "./RV";
import OurLocations from "./OurLocations";


ReactDOM.render(
  <React.StrictMode>
  <Navbar />
  <BrowserRouter >
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="my-profile" element={<MyProfile />}  />

    <Route exact path="listing/:place/:checkIn/:checkOut/:guest" element={<Listing />}  />

    <Route path="MapProperties/:checkIn/:checkOut/:guest" element={<OpenMap />}  />
    <Route path="singleProperty/:id/:checkIn/:checkOut/:accommodation/:rate/:address/:guest/:nights" element={<SingleProperty />}  />
    <Route path="signIn" element={<Login />}  />
    <Route path="signUp" element={<SignUp /> } />
    <Route path="careers" element={<Careers />} />
    <Route path="job-apply" element={<JobApply />} />
    <Route path="become-a-host" element={<HostPage />} />
    <Route path="about-orange-loft" element={<AboutOrangeLoft />} />
    <Route path="forget-password" element={<ForgetPassword />} />
    <Route path="reset-password" element={<ResetPassword />} />
    <Route path="vr-tour" element={<VrTour />} />
    <Route path="rooms/:id/:checkIn/:checkOut/:accommodation/:address/:guest/:nights" element={<Rooms />} />
    <Route path="book-now/:accommodation_Id/:checkIn/:checkOut/:accommodation/:address/:guest/:nights/:rate/:rooms/:roomtype/:rateplan" element={<BookNow />} />
    <Route path="thanks" element={<Thanks />} />
    <Route path="perfect-trip" element={<Blog1 />} />
    <Route path="book-with-flexibility" element={<Blog2 />} />
    <Route path="blog-3" element={<Blog3 />} />
    <Route path="book" element={<BookNowPage />} />
    <Route path="rv" element={<RV />} />
    <Route exact path="our-locations" element={<OurLocations />} />
  
  

  
  </Routes>
</BrowserRouter> 
  </React.StrictMode>,
  document.getElementById('root')


);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
