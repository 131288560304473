import {React,useEffect} from "react";
import Navbar from "./Navbar";
import './App.css';
import Slider from "./Slider";
import TopCards from "./TopCards";
import RecommendedStays from "./RecommendedStays";
import TopDestinations from "./TopDestinations";
import Host from "./Host";
import Footer from './Footer';


function Home() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
   
    return (
        <div >
        <Navbar />
        <Slider />
        <TopCards />
        <RecommendedStays />
        <TopDestinations />
        <Host />
        <Footer />


        </div>
    );

}

export default Home;