
import {React , useState, useEffect} from 'react';
import HoverImage from "react-hover-image";
import { GoogleMap, Circle, LoadScript, OverlayView ,ScriptLoaded,InfoWindow,Rectangle, Marker } from '@react-google-maps/api';
import $ from 'jquery';
import "./App.css";


const mapContainerStyle = {
    height: "400px",
    width: "100%",
    border:"none",
    borderRadius:"13px"
  }
  
  const center = {
    lat: 34.061528003117765,
    lng: 73.43010932612995
  }
  const onLoad = infoWindow => {
    console.log('infoWindow: ', infoWindow)
  }
 

  const positionMurree = { 
    lat: 34.061528003117765,
    lng: 73.43010932612995, }

    const positionNaran = { 
      lat: 34.90256586746212, 
      lng: 73.64981125458775}

      const positionKalam = { 
        lat:35.48138498602593, 
        lng: 72.59119207807571
      }
      

  
  const divStyle = {
   borderRadius:"100px",
   height:"100px",
   width:"100px",
   padding:"0px",
   textAlign:"center",
  }
  

function Map2() {

    const [state, setState] = useState(false);
    const [stateNaran, setStateNaran] = useState(false);
    const [stateKalam, setStateKalam] = useState(false);

    const onMouseOverKalam = () => {
      setStateKalam(true);
    }

    const onMouseOutKalam = () => {
      setStateKalam(false);
    }

    const onMouseOverNaran = () => {
      setStateNaran(true);
    }

    const onMouseOutNaran = () => {
      setStateNaran(false);
    }

    const onMouseOver = () => {
        setState(true);
    }

    const onMouseOut =() =>  {
        setState(false);
    }

    const titleNaran ="Naran By Pass Road Hotel";
    const titleMurree= "Murree Orangeloft Hotel";
    const titleKalam = "Kalam Orangeloft Hotel";

  
    return (
      <LoadScript
      googleMapsApiKey="AIzaSyCKJNs6TsHyxdiKxFi6afnXTzJDA805n7s"
    >
    
        <GoogleMap
          id="rectangle-example"
          mapContainerStyle={mapContainerStyle}
          zoom={7}
          center={center}
        >
        
          <div >       
          </div>
          
          

          <div>
          <Marker key="marker_1"
          icon={{
              url: '/images/bed.png',
              border: {border:"1px solid red;"},
              size: {width: 60, height: 100}, anchor: {x: 15, y: 50}, scaledSize: {width: 50, height: 50},
          }}
          position={positionMurree}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          title={titleMurree}
      />
     {state && (
      <InfoWindow
      onLoad={onLoad}
      position={positionMurree}
    >
      <div style={divStyle}>
        <img src="/images/bigImage.jpg" className="hoverImage" />
        <p>Murree</p>
      </div>
    </InfoWindow> 
     )
     }

      <Marker key="marker_2"
          icon={{
              url: '/images/bed.png',
              border: {border:"1px solid red;"},
              size: {width: 60, height: 100}, anchor: {x: 15, y: 50}, scaledSize: {width: 50, height: 50},
          }}
          position={positionNaran}
          title={titleNaran}
          onMouseOver={onMouseOverNaran}
          onMouseOut={onMouseOutNaran}  />
      
          {stateNaran && (
            <InfoWindow
            onLoad={onLoad}
            position={positionNaran}
          >
            <div style={divStyle}>
              <img src="/images/room2.jpg" className="hoverImage" />
              <p>Naran</p>
            </div>
          </InfoWindow> 
           )
           }


           <Marker key="marker_3"
           icon={{
               url: '/images/bed.png',
               border: {border:"1px solid red;"},
               size: {width: 60, height: 100}, anchor: {x: 15, y: 50}, scaledSize: {width: 50, height: 50},
           }}
           position={positionKalam}
           title={titleKalam}
           onMouseOver={onMouseOverKalam}
           onMouseOut={onMouseOutKalam} 
           
           />
           {stateKalam && (
            <InfoWindow
            onLoad={onLoad}
            position={positionKalam}
          >
            <div style={divStyle}>
              <img src="/images/room3.jpg" className="hoverImage" />
              <p>Kalam</p>
            </div>
          </InfoWindow> 
           )
           }
      
          </div>
    
   
        </GoogleMap>
        </LoadScript>
    
  )
  
}

export default Map2;