import {React, useState} from "react";
import Navbar from "./Navbar";
import "./App.css";
import Footer from "./Footer";

const RV = () => {


  const [guestName, setGuestName] = useState();
  const [guestEmail, setGuestEmail] = useState();
  const [guestPhone, setGuestPhone] = useState();
  const [guestCity, setGuestCity] = useState();

   function submitForm(e){
        e.preventDefault();
        console.log('Here');
   }

    return(
        <div>
        <Navbar />

       <div className="container mt-5">

       <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
  <ol className="carousel-indicators">
    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
 
  </ol>
  <div className="carousel-inner carousel-inner1" >
    <div className="carousel-item active">
      <img className="w-100 slider-img" src="./images/rv7.jpeg" alt="First slide"  />
    </div>
    <div className="carousel-item">
      <img className="d-block w-100" src="./images/rv3.jpeg" alt="Second slide" />
    </div>
    <div className="carousel-item">
      <img className="d-block w-100" src="./images/rv4.jpeg" alt="Third slide" />
    </div>
    <div className="carousel-item">
      <img className="d-block w-100" src="./images/rv5.jpeg" alt="Third slide" />
    </div>
    <div className="carousel-item">
      <img className="d-block w-100" src="./images/rv1.jpeg" alt="Third slide" />
    </div>
    
    <div className="carousel-item">
      <img className="d-block w-100" src="./images/rv8.jpeg" alt="Third slide" />
    </div>
  </div>
  <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="sr-only">Next</span>
  </a>
</div>
       </div>


       <div className="container mt-5">
       <div className="row">
       <div className="col-sm-3 card py-3 px-5 ">
       <h4 className="text-orange  ">Occupancy</h4>
       <p>6 People Occupancy</p>
       <h4 className="text-orange  ">Rent</h4>
       <p>Rs. 60,000/Per Day</p>
       </div>
       <div className="col-sm-1">
       </div>

       <div className="col-sm-8 card py-3 px-5 ">
      
       <form onSubmit={submitForm}>

       <div className="row">
       <div className="col-sm-6 ">
       <label className="form-label mt-1">Full Name</label>
       <input type="text" 
       value={guestName}
       onChange={(e) => setGuestName(e.target.value)}
       className="form-control" 
       placeholder="Enter Full Name"
       />
       <label className="form-label mt-1">Phone</label>
       <input type="text"
       value={guestPhone}
       className="form-control"
       onChange={(e) => setGuestPhone(e.target.value)}
       placeholder="Enter Contact Number" />
       </div>

       <div className="col-sm-6">
       <label className="form-label mt-1">Email</label>
       <input type="email"
       value={guestEmail}
       onChange={(e) => setGuestEmail(e.target.value)}
       className="form-control" placeholder="Enter Email" />

       <label className="form-label mt-1">City</label>
       <input type="text"
       value={guestCity}
       onChange={(e) => setGuestCity(e.target.value)}
       className="form-control" placeholder="Enter City" />
       </div>
       </div>

       <div className="row">
       <div className="col-sm-12 text-center mt-5">
       <button type="submit" className="booking-btn" >Submit</button>
       </div>
       </div>
       </form>
       </div>
       </div>
       </div>

       

        <Footer />

        </div>
    )
}

export default RV;