import React from "react";

const MyReservations = () => {
    return (
        <div>

        <div className="container">
        <div className="row">
        <div className="col-sm-12 text-center mt-5">
        <h2>Reservations History</h2>
        </div>
        </div>


        <div className="row">
        <div className="col-sm-12 mt-3">
        <table class="table table-hover table-bordered">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Booking Id</th>
      <th scope="col">Check In</th>
      <th scope="col">Check Out</th>
      <th scope="col">Total Stay</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>1675</td>
      <td>12-22-2022</td>
      <td>13-22-2022</td>
      <td>1 Night</td>
    </tr>
    <tr>
    <th scope="row">2</th>
    <td>1579</td>
    <td>10-12-2022</td>
    <td>13-12-2022</td>
    <td>3 Nights</td>
    </tr>
    <tr>
    <th scope="row">3</th>
    <td>1405</td>
    <td>29-8-2022</td>
    <td>30-8-2022</td>
    <td>1 Night</td>
    </tr>
    <tr>
    <th scope="row">4</th>
    <td>1375</td>
    <td>10-7-2022</td>
    <td>12-7-2022</td>
    <td>2 Nights</td>
    </tr>
   
  </tbody>
</table>
        </div>
        </div>
        </div>
        
        </div>
    )
}

export default MyReservations;