import {React, useEffect} from "react";
import Navbar from "./Navbar";
import './App.css';
import Footer from "./Footer";
import { Link } from "react-router-dom";

const Careers = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div>
        <Navbar />
        <div className="container">
        <div className="row">
        <div className="col-sm-12 mt-3">
        <h2>Careers</h2>
        <div className="card px-3 py-3 mt-3">
        <div className="col-sm-12">
        <h5>Web Developer</h5>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to</p>
        <Link to="/job-apply" ><button className="applyNow-btn">Apply Now</button></Link>
        </div>
        </div>
        <div className="card px-3 py-3 mt-3">
        <div className="col-sm-12">
        <h5>Laravel Developer</h5>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to</p>
        <button className="applyNow-btn">Apply Now</button>
        </div>
        </div>
        </div>
        </div>
        </div>

        <Footer />
        </div>
    )
}

export default Careers;