import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import $ from 'jquery';
import { Link, useNavigate } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import FlashMessage from "react-flash-message";
import "./App.css";


const ForgetPassword = () => {





    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])


    const [email, setEmail] = useState();
    const [error, setError] = useState();
    const [success, setSuccess] = useState();

    async function resetPassword(e){
        e.preventDefault();

    let result = await fetch(`http://192.168.100.110:8000/api/forget-password?email=${email}` , {
        method:"POST",
        body:JSON.stringify(email),
        headers:{
            "Content-type":"application/json",
            "Accept":"application/json",
        }
    });

    result = await result.json();
    // console.log(result.message);
    if(result.message === "Trying to get property 'email' of non-object"){
        setError('User doesnot exists in our record');
    }
    else{
        setSuccess('Please Check your mail to update password');
    // console.log('here');
}


 
}


return (
    <div>
    <Navbar />

    <div className="container">
    <div className="row">
    <div className="col-sm-3">
    </div>
    <div className="col-sm-6">
    <div className="card py-5 px-5">
    <h2 className="text-center pb-3 testing">Recover Password</h2>
    {error &&
        <Alert variant="danger">
        <span>{error}</span>
       
      </Alert>
    }
    {success &&
        <Alert variant="success">
        <span>{success}</span>
       
      </Alert>
    }
        <div className="form-group">
        <label className="form-label">Enter your Email </label>
        <input type="email"
         value={email}
          placeholder="Enter Email"
          onChange={(e) =>setEmail(e.target.value) }
          required
          className="form-control"
          id="email" />
        </div>

        <div className="form-group text-center pt-3">
        
        <button className="signIn-btn" onClick={resetPassword} >Submit</button>
      {/*  <Link to="/reset-password" className="font-weight-bold">Reset Password</Link> */}
        </div>
    </div>
    </div>
    <div className="col-sm-3">
    </div>
    </div>
    </div>
    <Footer />

    

    </div>
)
}


export default ForgetPassword;