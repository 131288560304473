import React, {useState, useCallback, useEffect} from "react";
import Navbar from './Navbar';
import './App.css';
import Footer from "./Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import Slider from '@mui/material/Slider';
import Map2 from "./Map2";
import axios from "axios";



const OpenMap = () =>{
  const {place, checkIn, checkOut, guest} = useParams();
  const [isLoading, setIsLoading] = useState(false);
  

 const sidebarCheckIn = new Date(checkIn).toLocaleString('en-us',{month:'short', day:'numeric'});
 const sidebarCheckOut = new Date(checkOut).toLocaleString('en-us',{month:'short', day:'numeric'});

  const navigate = useNavigate();
    const [opacity, setOpacity] = useState(0);

    const appStyles={
      opacity:`${opacity}`
    }

    const  [toggleHeart, setToggleHeart] = useState(false);

    const [value, setValue] =  useState([0,100000]);

    
    const changeColor = useCallback(() =>{
     setToggleHeart(!toggleHeart)
    },[]);


const [accommodations, setAccommodation] = useState([]);
  useEffect(() => {
      async function getData() {
        setIsLoading(true);
        const res = await axios.get(`https://dev.techneeq.org/api/Accomodations?search=h&invFrom=${checkIn}&invTo=${checkOut}&adults=2&min=${value[0]}&max=${value[1]}`);
        console.log(res.data);
        // setNights(res.data.totalStay);
        
        // console.log(res.data.accommodation[0].room_types[0].MaxPersons);
        setAccommodation(res.data.accommodation);
        setIsLoading(false);
  
            
      }
      getData();
  },[]);


  const rangeSelector = (event, newValue) => {
    setValue(newValue);
  
    async function getData() {
  
      const res = await axios.get(`https://dev.techneeq.org/api/Accomodations?search=h&invFrom=${checkIn}&invTo=${checkOut}&adults=2&min=${value[0]}&max=${value[1]}`);
     
      // console.log(res);
      // setNights(res.data.totalStay);
      
      // console.log(res.data.accommodation[0].room_types[0].MaxPersons);
      setAccommodation(res.data.accommodation);
  
          
    };
  
    getData();
  
  };
    return (
        <div>
        <Navbar />
        <div className="container">
        <div className="row mt-5">
        <div className="col-sm-12 px-0 ">
        
        <h2>Galliyat, KPK</h2>
        
        <p>{sidebarCheckIn} - {sidebarCheckOut} <span className="dot">.</span>2 adults</p>
        </div>
        </div>

        <div className="row">
        <div className="col-sm-3 mt-3">
        <div className="btn-image">
        <button className="btn btn-light" onClick={() => navigate(-1)}>Back to list</button>
        </div>
        </div>

        <div className="col-sm-3">
        <p className="font-weight-bold mt-3">Price</p>
        <Slider
        value={value}
        min={0}
        max={100000}
        onChange={rangeSelector}
        valueLabelDisplay="auto"
      />
        <p className="flex-container mt-1"><span>Rs.0</span><span className="max-price">Rs.100,000</span></p>
        </div>

        <div className="col-sm-6 px-0">
        <p className="flex-container1 font-weight-bold mt-3"><span>Property Portal</span><span className="max-price"></span></p>
        <div>
        <label className="container1"><span className="span-class">Hotel</span>
        <input type="checkbox"  className="input" />
        <span className="checkmark"></span>
      </label>
      <label className="container1"><span className="span-class">Serviced Apartment</span>
      <input type="checkbox" className="input"/>
      <span className="checkmark"></span>
       
      </label>
      <label className="container1"><span className="span-class">Homestay</span>
        <input type="checkbox" className="input" />
        <span className="checkmark"></span>
      </label>
      <label className="container1"><span className="span-class">Hostel</span>
        <input type="checkbox" className="input"/>
        <span className="checkmark"></span>
      </label>
      </div>
        </div>


        </div>


        <div className="row mt-3">
        
        <div className="col-sm-7">
        {isLoading ? 
          <div className="col-sm-12 text-center">
          <div className="spinner-border spinner-color text-center" role="status">
          <span className="sr-only">Loading...</span>
        </div>
          </div>
          :  
        
        accommodations.map((accommodation) => {
          return (
            <div>
        
            {accommodation.room_types.map((room_type, i) => {
              return (
                <div>
                {i == 0 && 
                  <div className="card mt-4" >
                  <div className="row no-gutters">
          
                  <div className="col-sm-4 mt-3 mb-3 ml-3" >
                  <div id="demo" className="carousel slide" data-ride="carousel" onMouseEnter={() => setOpacity(1)} onMouseLeave={() => setOpacity(0)} >
          
              
                  <ul className="carousel-indicators">
                    <li data-target="#demo" data-slide-to="0" className="active"></li>
                    <li data-target="#demo" data-slide-to="1"></li>
                    <li data-target="#demo" data-slide-to="2"></li>
                  </ul>
                  
            
                  <div className="carousel-inner" >
                    <div className="carousel-item active carousel-item1 ">
                    
                      <img src="/images/bigImage.jpg" alt="Los Angeles" className="img"   />
                    </div>
                    <div className="carousel-item carousel-item1">
                      <img src="/images/room1.jpg" alt="Chicago" style={{height:"200px"}}  />
                    </div>
                    <div className="carousel-item carousel-item1">
                      <img src="/images/room2.jpg" alt="New York" style={{height:"200px"}}  />
                    </div>
                  </div>
                  
                 
                  <a className="carousel-control-prev" href="#demo" data-slide="prev" style={appStyles} >
                    <span className="carousel-control-prev-icon"></span>
                  </a>
                  <a className="carousel-control-next" href="#demo" data-slide="next" style={appStyles} >
                    <span className="carousel-control-next-icon"></span>
                  </a>
                
                  
                  
                  </div>
          
                  </div>
                   
                  <div className="col-sm-7 mt-4 ml-3 mr-0">
                  <div className="row">
                  <div className="col-sm-12">
                  <div className="row">
                  <div className="col-sm-11">
                 
                  <span className='repo-text'> </span>
                  {accommodation.room_types.map((room_type, i) => {
                    return (
                      <div>
                      {i <= 0 && 
                        
                        <Link to={`/singleProperty/${accommodation.AccommodationId}/${checkIn}/${checkOut}/${accommodation.AccommodationName}/${room_type.RackRate}/${accommodation.Address}/${guest}/2`} className="nav-link1">
                      <h5>{accommodation.AccommodationName}</h5>
                      <h5>{`${accommodation.Address.substring(0, 30)}`}</h5>
                      </Link>
                    }
                      
                   </div>
                    ); }
                   )}
                 
                
                
                  </div>
                  <div className="col-sm-1">
                  <i className={
                      toggleHeart ? 'heart active far fa-heart' : 'heart far fa-heart'
                    } onClick={changeColor}></i>
                  </div>
                  </div>
                  
                  {accommodation.room_types.map((room_type, i) => {
                    return (
                      <div>
                      {i <= 0 && <p>{room_type.MaxPersons} persons {room_type.NoOfBeds} Bedrooms {room_type.BathRoomDetail} Bathrooms</p>}
                      
                   </div>
                    ); }
                   )}
                  <br />
                  </div>
                  
              
                  </div>
          
                  <div className="row">
          
                  <div className="col-sm-1 mr-1">
                  <h4 className="rating text-white">4.4</h4>
                  </div>
          
                  <div className="col-sm-5 excellentleft">
                  <p><span className="font-weight-bold">Excellent </span><br /><span className="price">226 Reviews</span></p>
                  
                  </div>
          
                  
          
                  <div className="col-sm-5 px-0 textright">
                  {accommodation.room_types.map((room_type, i) => {
                    return (
                      <div>
                      {i <= 0 && <p><span className="font-weight-bold">{room_type.RackRate}.PKR </span><span className="price">/night<br />All taxes and fees included</span></p>}
                      
                   </div>
                    ); }
                   )}
                  
                 
                  </div>
                  </div>
          
                  </div>
          
                  
                  </div>
                  
                  </div>
                }
                
                
             </div>
              ); }
             )}  

              </div>
              );
            })}
     
       

     


        



      </div>
  

        <div className="col-sm-5 mt-4 ">
        <Map2 className="sticky" />
        </div>
        </div>
        </div>


        <Footer />
        </div>
        );
}

export default OpenMap;