import { React , useEffect } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import "./App.css";

const Blog1 = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(
        <div>
        <Navbar />

        <div className="container-fluid">
        <div className="row">
        <div className="col-sm-12">
        <img src="/images/card1.jpg" alt="Blog-image" className="blog-img1" />
        </div>
        </div>
        </div>


        <div className="container">
        <div className="row">
        <div className="col-sm-12 mt-5">
        <h2>Tourism in Pakistan</h2>
        <p>
        Pakistan remains a land of thrilling adventure and breathtakingly beautiful nature, from the massive lengths of the Karakorum in the north to the wide alluvial delta of the Indus River in the south. Trekking, mountaineering, white water rafting, wild boar hunting, mountain and desert jeep safaris, camel and yak safaris, trout fishing, and bird viewing are just some of the activities that draw adventure and environment enthusiasts to Pakistan.
Pakistan is home to a plethora of natural wonders as well as historical and religious tourist attractions that draw a great number of local and international visitors each year. In 2020, however, the COVID-19 pandemic wreaked havoc on Pakistan's tourism industry. Local tourists have strengthened the local tourism business this year, despite the limited intake of tourists from abroad due to pandemic-related travel restrictions.
<br /><br />
The current government has made tourism a priority. Several initiatives are being attempted to capitalize on the tourism industry's potential. The National Tourism Coordination Board (NTCB) was established by the government to develop and improve coordination with provinces, federal ministries, and private actors, to facilitate the development of policies, strategies, frameworks, and other tools to market and promote tourism potential nationally and internationally, and to build capacity for the provision of quality human resources.
<br /><br />
Pakistan generates only 0.1 percent of the US$352 million in international tourism revenue.  when considering the intricate dynamics of tourism, it is not a disappointment. It also demonstrates that this is merely one aspect of a larger picture. International tourist expenditures account for only 28.7% of total tourism expenditures when global tourism expenditures are separated. While domestic expenditures make for 71.3 percent of total expenditures. Domestic tourism is described as tourists traveling within their own country, whereas international tourism refers to people visiting other countries. This is an excellent place to start for Pakistan. Because Pakistan has a vast population and a growing middle class, traveling to international destinations is difficult.
<br /><br />
Even the small service providers have been pushed to consider safety as a priority. Travel safety policies must be implemented and changed on a regular basis to guarantee that the lack of restrictions does not act as a disincentive to domestic tourists. Customers in the industry will be more likely to make informed decisions as a result of this, and with the development of digital platforms, information has never been more accessible. We simply need to ensure that the information provided is reliable, timely, and presented in a way that everyone, including those with less technical understanding, can understand.

        </p>
        
        </div>
        </div>
        </div>

        <Footer />
        </div>
    )
}

export default Blog1;