import {React, useState , useEffect} from "react";
import './App.css';
import Navbar from "./Navbar";
import { Link, useNavigate } from "react-router-dom";
import Footer from "./Footer"
import SweetAlert from 'react-bootstrap-sweetalert';
import FlashMessage from 'react-flash-message';
import Alert from 'react-bootstrap/Alert'

const Login = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    const navigate = useNavigate();

    

    const [email, setEmail] = useState();
  
    const [password , setPassword] = useState();

    const [state1, setState] = useState();

    const [error, setError] = useState();

    const [success, setSuccess] = useState();

 

    let user = [email,password];


    async function signIn(e){
        e.preventDefault();
        let item = { email , password};
        // console.warn(item);
        
 
         let result = await fetch("https://dev.techneeq.org/api/authenticate" , {
             method:"POST",
             body:JSON.stringify(item),
             headers:{
                 "Content-type":"application/json",
                 "Accept":"application/json",
             }
         });
 
         result = await result.json();
        //  console.log(result.user);
        //  console.log(result.token);
         if(result.token)
         {
            let userData = {
                email: email,
                access_token: result.token,
              };
              let appState = {
                  name: result.user.name,
                  id: result.user.id,
                isLoggedIn: true,
                item: userData
              };
              localStorage["appState"] = JSON.stringify(appState);
              navigate('/');
           
             
         }
         else{
            
             console.log(Response.statusText);
             setError('User doesnot exist with these Credentials');
            //  alert("User doesnot exist with these Credentials");
         }
         

         let state = localStorage["appState"];
         
         const AppState = JSON.parse(state);
         setState({ isLoggedIn: AppState.isLoggedIn, user: AppState.item });
         
         console.log(AppState.item.email);
     }


    return (
        <div>
        <Navbar />
        <div className="container">
        <div className="row">
      
        <div className="col-sm-12 justify-content-center ">
        <form onSubmit={signIn}>
        <div className="row">
        <div className="col-sm-12">
        </div>
        <div className="col-sm-3"></div>

        <div className="col-sm-6 card py-5">

        
        <h2 className="text-center">Sign In</h2>
        
        <span className="text-success bg-success">{success}</span>
        {error &&
            <Alert variant="danger">
            <Alert.Heading>{error}</Alert.Heading>
           
          </Alert>
        }
        <div className="form-group">
        <label className="form-label">User Email</label>
        <input type="email"
         name="email"
         value={email}
          placeholder="Enter Email"
          onChange={(e) =>setEmail(e.target.value) }
          required
          className="form-control" />
        </div>

        <div className="form-group">
        <label className="form-label">Password</label>
        <input type="password" 
        value={password}
        onChange={(e) =>setPassword(e.target.value) } name="password" 
        placeholder="Enter Password"
        required
         className="form-control" />
        </div>

       <div className="row">
       <div className="col-sm-6">
       <div className="form-group ">
        <label className="font-weight-bold"><Link to="/forget-password">Forget Password?</Link></label>
        </div>
       </div>
       <div className="col-sm-6">
       <div className="form-group text-right">
        
        <label className="form-label ml-2 text-right">New User? <Link to="/signUp" className="font-weight-bold">Sign Up</Link></label>
        </div>
       </div>
       </div>



        <div className="form-group text-center">
        
        <button className="signIn-btn" >Sign In</button>
        </div>

        </div>

        <div className="col-sm-3">
        </div>

        </div>


        </form>
        </div>
        </div>
        </div>
        <Footer />
        </div>
    );
}

export default Login;