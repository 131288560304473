import { React , useEffect } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import "./App.css";

const Blog2 = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(
        <div>
        <Navbar />

        <div className="container-fluid">
        <div className="row">
        <div className="col-sm-12">
        <img src="/images/glamp.jpg" alt="Blog-image" className="blog-img1 img-fluid" style={{height:'400px'}}   />
        </div>
        </div>
        </div>


        <div className="container">
        <div className="row">
        <div className="col-sm-12 mt-5">
        <h2>Luxury Glamping pods & Huts</h2>
        <p>
        By 2028, the global glamping industry is anticipated to be worth USD 5.41 billion. From 2021 to 2028, it is predicted to grow at a CAGR of 14.1%. Preference for experience-oriented travels above beautiful belongings and willingness to pay for opulent travel are two prominent variables influencing consumers' inclination to book a glamping stay. Young travelers are being influenced by the availability of exotic and unusual landscapes as well as lavish facilities to explore and invest in such services.
<br /><br />
It provides adventure travelers with the comforts of home as well as the amenities of a luxury hotel while being surrounded by nature. Cabins and pods also include a variety of safety features, like locks and latches, which are a must-have among today's consumers.
<br /><br />
Furthermore, as social media has evolved as a digital advertising platform, market participants are using it to promote their products. Many glamping service providers create packages to meet the needs of their customers. This has also played a significant role in consumers' desire to use these services. Honey Glamping, situated in New Zealand, for example, provides mobile glamping services in any place in the Greater Auckland area, as well as organizing festivals, staycations, and other events.
<br /><br />

In 2020, the 18-32 age group segment dominated the market, accounting for 44.6 percent of total sales. According to research by Kampgrounds of America, Inc., millennials and Generation Z accounted for 60% of total leisure travelers in the American glamping market. Furthermore, the increasing popularity of solo travel among females is influencing the gender to choose glamping. Furthermore, 20 percent of glamping guests are couples without children. As a result, the market for glamping is being driven by a growing number of younger travelers with more disposable cash.
Glamping pods are in demand because of the elevated experience that is provided. Moreover, the pandemic has changed travel and stay choices, which has really helped this segment grow.

        </p>
        
        </div>
        </div>
        </div>

        <Footer />
        </div>
    )
}

export default Blog2;