import {React , useState} from "react";
import './App.css';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Login from "./Login";
import { Link } from "react-router-dom";
import CreditCardIcon from '@mui/icons-material/CreditCard';


const Footer = () => {
    const todayDate = new Date().toISOString().slice(0, 10);

    const date = new Date(Date.now() + ( 3600 * 1000 * 24));
    const tomorrow = date.toISOString().slice(0, 10);
    const [guest, setGuest] = useState(1);
    const [checkIn, setCheckIn] = useState(todayDate);
    const [checkOut, setCheckOut] = useState(tomorrow);
    const [place, setPlace] = useState("");

    
  const [accommodations, setAccommodation] = useState([]);


  const [nights, setNights] = useState();

  const [value, setValue] =  useState([0,90000]);

    const src ="/images/logo.jpg";
    return(
        <div>
        <div className="container-fluid footer mt-4">

        <div className="row">
        <div className="col-sm-12">
        <div className="container">
        <div className="row pb-5">

        <div className="col-sm-4 pt-5">
        <img src={src} alt="logo" className="footer-logo-img" />
        <p className="mt-3">Orange Loft is a pioneering hospitality brand up-North that guarantees tourists an unforgettable stay in all the Cultural/Scenic landmarks of Pakistan.
        </p>
        <a href="https://www.facebook.com/orangeloftpakistan/">
        <FacebookRoundedIcon className="social-icon"/></a>
        <a href="https://www.instagram.com/orangeloft.pk/?igshid=YmMyMTA2M2Y=">
        <InstagramIcon className="social-icon" /></a>
        
        

        
        
        </div>
        <div className="col-sm-1 pt-5">
        </div>

        <div className="col-sm-3 pt-5">
        <p className="font-weight-bold">About</p>
        <ul>
        <li><Link to="/about-orange-loft" className="footer-links">About Orange Loft</Link></li>
        <li><Link to="/careers" className="footer-links">Careers</Link></li>
        <li><Link to="/become-a-host" className="footer-links">Become a Host</Link></li>
     
        <li><Link to="/signIn"  className="footer-links ">Login</Link></li>
        
       
        </ul>
        </div>

        <div className="col-sm-2 pt-5">
        <p className="font-weight-bold">Locations</p>
        <ul>
        <Link to={`listing/Orangeloft%20Attabad%20Lake/${checkIn}/${checkOut}/${guest}`} className="link">
        <li><span className="footer-links">Hunza Valley</span></li>
        </Link>

        <Link to={`/listing/Orangeloft%20Shogran/${checkIn}/${checkOut}/1`} className="link">
        <li><span  className="footer-links">Shogran</span></li>
        </Link>

        <Link to={`/listing/Orangeloft%20Naraan/${checkIn}/${checkOut}/1`} className="link">
        <li><span className="footer-links">Naran Valley</span></li>
        </Link>

         <Link to={`listing/Orangeloft%20Mahodand%20Lake/${checkIn}/${checkOut}/${guest}`} className="link">
        <li><span className="footer-links">kalam valley</span></li>
        </Link>
 
        <Link to={`listing/Orangeloft%20Walled%20City%20Lahore/${checkIn}/${checkOut}/${guest}`} className="link">
        <li><span className="footer-links">Lahore</span></li>
        </Link>  

  
        <Link to={`listing/Orangeloft%20Skardu%20(Lower%20Kuchura%20Lake)/${checkIn}/${checkOut}/${guest}`} className="link">
        <li><span className="footer-links">Skardu</span></li>
        </Link>


          <Link to={`listing/Orange%20loft%20Khanpur%20Lake/${checkIn}/${checkOut}/${guest}`} className="link">
        <li><span className="footer-links">Khanpur</span></li>
        </Link>
    
        <Link to={`listing/Orangeloft%20Glamp%20park/${checkIn}/${checkOut}/${guest}`} className="link">
        <li><span className="footer-links">Murree</span></li>
        </Link> 

        </ul>
        </div>
       
        {/* <div className="col-sm-2 pt-5">
        <p className="text-gray">Payment Methods</p>
        
        <span className="cards1 "><CreditCardIcon /></span>
        <i className="fab fa-cc-visa cards"></i>
        <i className="fab fa-cc-mastercard cards"></i>
        </div> */}

           <div className="col-sm-2 pt-5">
        <p className="text-gray font-weight-bold">Customer Support:</p>
        
        <span className=" ">{'+92 302 1115638'}</span>
        
        </div> 

        </div>
        </div>
        </div>
        </div>

        <div className="row">
        <div className="col-sm-12">
        <div className="container">
        <div className="row">
        <div className="col-sm-12 style1" >
        <ul className="ul">
        <li className="li ">&copy; 2022 | OrangeLoft Pakistan | Powered by TECHNEEQ</li>
        <li className="li mobilenav1"><span className="dot">.</span>Privacy Policy</li>
        <li className="li mobilenav2"><span className="dot">.</span>Terms & Conditions</li>
        
        </ul>
        </div>
        
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
    );
}

export default Footer;