import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const JobApply = () => {
    return (
        
        <div>
        <Navbar />
        <div className="container">
        <div className="row mt-4">
        <div className="col-sm-12 text-center">
        <h2>Your are applying for Web Developer Post</h2>
        </div>
        </div>
        <div className="row">
    
        <div className="col-sm-3">
        </div>
        <div className="col-sm-6">
       
        <div className="form-group">
        <label className="form-label">Name</label>
        <input type="name" name="name" placeholder="Enter Name" className="form-control" />
        </div>

        <div className="form-group">
        <label className="form-label">User Email</label>
        <input type="email" name="email" placeholder="Enter Email" className="form-control" />
        </div>

        <div className="form-group">
        <label className="form-label">Phone No</label>
        <input type="text" name="phone" placeholder="Enter Phone Number" className="form-control" />
        </div>

        <div className="form-group">
        <label className="form-label">Upload CV</label>
        <input type="file" name="cv"  className="form-control" />
        </div>

        <div className="form-group text-center">
        
        <button className="signIn-btn">Submit</button>
        </div>
      
        </div>
        <div className="col-sm-3">
        </div>
        </div>
        </div>

        <Footer />
        </div>
    );
}

export default JobApply;